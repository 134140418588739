import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import * as fieldActions from 'actions/Fields';
import * as locationActions from 'actions/Geolocation';
import EditFieldsForm from 'mui-components/fields/fieldtab/general/EditFieldsForm';
import Loader from 'components/Loader';
import ScrollToTopOnMount from 'components/ScrollTopOnMount';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import Help from 'containers/help/Help';
import CreateFieldHelp from 'containers/help/CreateField';
import { ADMIN, OWNER, ADVISOR, NEW } from 'constants/index';
import { GAEvent } from 'utils/GoogleAnalytics';
import { GA_FIELD_HELP_TEXT_CLK, GA_CAT_CLICK } from 'constants/GoogleAnalytics';
import * as Seasons from 'actions/Seasons';
import * as FieldMethod from 'actions/FieldMethod';
import * as FieldMasterData from 'actions/FieldMasterData';

class EditFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      showDeletePrompt: false,
      selectedField: '',
      showHelp: false,
      updatedFields: false,
      previousIndex: null,
      nextIndex: null,
      currentFieldValue: ''
    };
  }

  componentDidMount() {
    const { fieldDetails, history, getGeoLocation, fetchFieldList, activeFarm, seasonsList, fieldMethodList, getFieldCrops, getPreviousFieldCrops } = this.props;
    seasonsList();
    fieldMethodList();
    getGeoLocation();
    getFieldCrops();
    getPreviousFieldCrops();
    if (!activeFarm) {
      history.push('/settings');
    }
    if (!fieldDetails && activeFarm) {
      fetchFieldList(activeFarm.farm.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { fieldDetails, activeFarm, location, history, match } = this.props;
    const { updatedFields } = this.state;
    if (prevProps.activeFarm !== activeFarm) {
      history.push('/managefields');
    }
    if (!updatedFields && fieldDetails && fieldDetails !== 'undefined') {
      this.intializeValue(match.params.id);
    }
	  if (location !== prevProps.location) {
      this.setState({
        selectedField: '',
        updateFieldDetails: true
      });
      this.intializeValue(match.params.id);
    }
  }

  setStateOfParent = (changeValue) => {
    const { fetchFieldList, activeFarm } = this.props;
    fetchFieldList(activeFarm.farm.id);
    if(changeValue !== null) {
      this.setState({currentFieldValue: {...changeValue}});
    } else {
      this.setState({selectedField: ''});
    }
  }

  // Intialize compoenent values by passing url param Id
  intializeValue = paramId => {
    const { saveFieldCoordinates, fieldDetails, history } = this.props;
    this.setState({ paramId, updatedFields: true });
    if (paramId !== NEW) {
      const currentField = _.filter(fieldDetails, ['fieldId', paramId]);
      let fieldDetailsSorted = _.orderBy(fieldDetails, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']);
      const currentIndex = fieldDetailsSorted.findIndex( fieldDetail => fieldDetail.fieldId === paramId );
      if (currentField.length > 0) {
        this.setState({
          selectedField: currentField[0],
          currentFieldValue: {...currentField[0]}
        });
        saveFieldCoordinates({
          coOrds: currentField[0].gpsPoints,
          area: currentField[0].fieldSize
        });
        if(currentIndex === 0 && currentIndex === fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: null
          });
        }
        if(currentIndex === 0 && currentIndex !== fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: fieldDetailsSorted[currentIndex+1].fieldId
          });
        }
        if(currentIndex !== 0 && currentIndex === fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
            nextIndex: null
          });
        }
        if(currentIndex !== 0 && currentIndex !== fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
            nextIndex: fieldDetailsSorted[currentIndex+1].fieldId
          });
        }
      } else {
        history.push('/managefields');
      }
    } else {
      const newField = { fieldCrop: '', fieldName: '', fieldSize: '', manureApplied: false, manurePlans: [], gpsPoints: [], note: '' };
      this.setState({
        selectedField: newField
      });
      saveFieldCoordinates({
        coOrds: [],
        area: 0
      });
    }
  };

  // re-initilize component value on click add button
  onAddClick = () => {
    // this.intializeValue(NEW);
  };

  // To show delete confirmation box
  showDialogDeleteField = () => {
    const { activeFarm, hasSupportAdminRole } = this.props;
    if (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data) {
      this.setState({ showDeletePrompt: true });
    }
    return false;
  };

  // To show help modal window
  showHelpToggle = () => {
    const { showHelp } = this.state;
    if (!showHelp) {
      GAEvent(GA_FIELD_HELP_TEXT_CLK, GA_CAT_CLICK, GA_CAT_CLICK);
    }
    this.setState(prevState => ({
      showHelp: !prevState.showHelp
    }));
  };

  /**
   * To show/hide prompt on delete field
   * and call delete field action
   */
  deleteFieldOnConfirm(action) {
    const { deleteField, activeFarm, history } = this.props;
    const { selectedField } = this.state;
    if (action) {
      deleteField(activeFarm.farm.id, selectedField.fieldId);
      history.push('/managefields');
    }
    this.setState(prevState => ({ showDeletePrompt: !prevState.showDeletePrompt }));
  }

  render() {
    const { paramId, selectedField, showDeletePrompt, showHelp, previousIndex, nextIndex, currentFieldValue } = this.state;
    const { intl, seasons, fieldMethod, fieldMasterData, history } = this.props;
    return (
      <div className="main-content">
        {!selectedField || seasons.seasonsList === null ? (
          <Loader styleName="field-loader" />
        ) : (
          <div className="main-route-area">              
            {selectedField && seasons.seasonsList !== null && fieldMethod.fieldMethodList && fieldMasterData && fieldMasterData.cropList !== null && (
              <EditFieldsForm
                selectedField={selectedField}
                paramId={paramId}
                isNewField={paramId === NEW}
                seasons={seasons}
                fieldMethod={fieldMethod}
                fieldMasterData={fieldMasterData}
                currentFieldValue={currentFieldValue}
                previousIndex={previousIndex}
                nextIndex={nextIndex}
                showDialogDeleteField={this.showDialogDeleteField}
                showHelpToggle={this.showHelpToggle}
                onAddClick={this.onAddClick}
                history={history}
                setStateOfParent={this.setStateOfParent}
              />
            )}
            
            {showDeletePrompt && (
              <ConfirmDialogBox
                heading={`${intl.formatMessage({ id: 'AEF_DF' })}?`}
                body={intl.formatMessage({ id: 'DIALOG_BODY_FIELD' })}
                confirmText="DELETE"
                cancelText="CANCEL"
                callback={action => this.deleteFieldOnConfirm(action)}
                warning
              />
            )}

            <Help
              showHelp={showHelp}
              showHelpClose={this.showHelpToggle}
              FeedbackBtnTxt={intl.formatMessage({ id: 'GIVE_FB' })}
            >
              <CreateFieldHelp />
            </Help>
          </div>
        )}
        <ScrollToTopOnMount />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fieldDetails: state.fieldDetails.data,
  fieldDetailsData: state.fieldDetails,
  activeFarm: state.farmList.activeFarm,
  seasons: state.seasons,
  fieldMethod: state.fieldMethod,
  fieldMasterData: state.fieldMasterData,
  userDetails: state.userDetails,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDispatchToProps(dispatch) {
  return {
    fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
    getGeoLocation: () => dispatch(locationActions.getGeoLocation()),
    clearMessages: () => dispatch(fieldActions.clearMessages()),
    saveFieldCoordinates: obj => dispatch(fieldActions.saveFieldCoordinates(obj)),
    deleteField: (farmId, fieldId) => dispatch(fieldActions.deleteField(farmId, fieldId)),
    seasonsList: () => dispatch(Seasons.seasonsList()),
    fieldMethodList: () => dispatch(FieldMethod.fieldMethodList()),
    getFieldCrops: () => dispatch(FieldMasterData.getFieldCrops()),
    getPreviousFieldCrops: () => dispatch(FieldMasterData.getPreviousFieldCrops())
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditFields)));
