import * as React from 'react';

import Header from 'containers/header';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import GrassAnalysisList from 'containers/grassanalysis/GrassAnalysisList';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
 
class GrassAnalysis extends React.Component {

  render() {
    return (
    <div className="full-width main-content">
      <Header bcTitle={<FormattedMessage id="GRASS_ANALYSIS" />} />
      <SectionContainer
        styling={{
          backgroundColor: 'white',
          paddingAllSides: 'low',
          mobileOffset: true
        }}
        className = "stats_header_class"
      >
        <GrassAnalysisList />
      </SectionContainer>
    </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(GrassAnalysis)));