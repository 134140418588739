import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import DatePicker from 'react-datepicker';
import { Stack, Grid, List, ListItemButton, Link, Collapse } from '@mui/material'
import { addMonths, subDays } from 'date-fns';

const CutUnitComponent = ({ handleClickCutUnit, intl, disableGrain, open3, cut1Date, cut1DateChange, cut2Date, cut2DateChange,
  cut3Date, cut3DateChange, cut4Date, cut4DateChange, calculateHistoricalData, cut1show, cut2show, cut3show, cut4show}) => (
  <List
    sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0' }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    <ListItemButton disableRipple sx={{ '&:hover': {backgroundColor: 'transparent'} }} onClick={handleClickCutUnit}>
      <span><ListItemText primary="Mineralisering" /></span>
      <span>{open3 ? <ExpandLess /> : <ExpandMore />}</span>
    </ListItemButton>
    <Collapse in={open3} timeout="auto" unmountOnExit>
      <List component="div" className='list-grid-box'>
        <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
          <Grid container justifyContent="flex-end">
            {cut1show && 
            <Grid item md={2}>
              <Stack className='text-with-padding-small'>
                <div>
                  <div className="datePickerTextbox">
                  {disableGrain === true && <FormattedMessage id="CUT_HARVEST" /> }
                  {disableGrain === false && <FormattedMessage id="CUT1_HARVEST" /> }
                  </div>
                  <DatePicker  
                    selected={cut1Date} 
                    onChange={cut1DateChange}
                    dateFormat="dd-MM"
                    dropdownMode="select" 
                    name="cut1Date"
                    fixedHeight
                    minDate={new Date("01-01-"+new Date().getFullYear())}
                    maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)}
                    style={{zIndex: 2}}
                  />
                </div>
              </Stack>
            </Grid>}
            {disableGrain === false && cut2show &&
            <Grid item md={2}>
              <Stack className='text-with-padding-small'>
                <div>
                  <div className="datePickerTextbox">
                    <FormattedMessage id="CUT2_HARVEST" />
                  </div>
                  <DatePicker  
                    selected={cut2Date} 
                    onChange={cut2DateChange}
                    dateFormat="dd-MM"
                    dropdownMode="select" 
                    name="cut2Date"
                    fixedHeight
                    minDate={new Date("01-01-"+new Date().getFullYear())}
                    maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)} 
                  />
                </div>
              </Stack>
            </Grid>}
            {disableGrain === false && cut3show &&
            <Grid item md={2}>
              <Stack className='text-with-padding-small'>
                <div>
                  <div className="datePickerTextbox">
                    <FormattedMessage id="CUT3_HARVEST" />
                  </div>
                  <DatePicker  
                    selected={cut3Date} 
                    onChange={cut3DateChange}
                    dateFormat="dd-MM"
                    dropdownMode="select" 
                    name="cut3Date"
                    fixedHeight
                    minDate={new Date("01-01-"+new Date().getFullYear())}
                    maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)} 
                  />
                </div>
              </Stack>
            </Grid>}
            {disableGrain === false && cut4show &&
            <Grid item md={2}>
              <Stack className='text-with-padding-small'>
                <div>
                  <div className="datePickerTextbox">
                    <FormattedMessage id="CUT4_HARVEST" />
                  </div>
                  <DatePicker  
                    selected={cut4Date} 
                    onChange={cut4DateChange}
                    dateFormat="dd-MM"
                    dropdownMode="select" 
                    name="cut4Date"
                    fixedHeight
                  />
                </div>
              </Stack>
            </Grid>}
            { (cut1show || cut2show || cut3show || cut4show) &&
            <Grid item md={4}>
              <Stack>
                <Link onClick={calculateHistoricalData} underline="none" sx={{color: '#00696D', paddingTop: '28px', paddingLeft: '20px'}}>
                  {intl.formatMessage({ id: 'CALCULATE_HISTORICAL_DATA' })}
                </Link>
              </Stack>
            </Grid>}
          </Grid>
        </ListItemButton>
      </List>
    </Collapse>
  </List>
);

export default CutUnitComponent;