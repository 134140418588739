import * as React from 'react';

import Header from 'containers/header';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import { FormattedMessage } from 'react-intl';

import StatsHeader from 'containers/stats/StatsHeader';
import StatsActivity from 'containers/stats/StatsActivity';
import Selectbox from 'components/forms/Selectbox';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as StatsAction from 'actions/Stats';
import { getYearsForKPI } from 'utils/index';
 
class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: null,
      completeSelectedYear: null
    };
  }
  componentDidMount = () => {
    const { activeFarm, fetchStatsHeader } = this.props;
    const currentYear = new Date().getFullYear();
    if (activeFarm) {      
      fetchStatsHeader(activeFarm.farm.id, currentYear);   
    }
    this.setState({
      selectedYear: currentYear,
      completeSelectedYear: { "label": currentYear, "value": currentYear}
    });
  };

  componentDidUpdate = prevProps => {
    const { activeFarm, fetchStatsHeader } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      const currentYear = new Date().getFullYear();
      fetchStatsHeader(activeFarm.farm.id, currentYear);
      this.setState({
        selectedYear: currentYear,
        completeSelectedYear: { "label": currentYear, "value": currentYear}
      });
    }
  };
 
  // on tab change passing page title to the header
  onSelectChange = (value) => {
    const { activeFarm, fetchStatsHeader } = this.props;
    fetchStatsHeader(activeFarm.farm.id, value.value);
    this.setState({
      selectedYear: value.value,
      completeSelectedYear: { "label": value.label, "value": value.value}
    });
  };
 
  render() {
    const {
      statsHeader: { isLoading, data, hasError },
      intl
    } = this.props;
    return (
    <div className="full-width main-content">
      <Header bcTitle={<FormattedMessage id="STATS" />} />
      <SectionContainer
        styling={{
          backgroundColor: 'white',
          paddingAllSides: 'low',
          mobileOffset: true
        }}
        className = "stats_header_class"
      >
        <StatsHeader
          isLoading = {isLoading}
          data = {data}
          hasError = {hasError}
          intl = {intl}
        />
      </SectionContainer>
      <SectionContainer
        styling={{
          backgroundColor: 'white',
          paddingAllSides: 'low',
          mobileOffset: true
        }}
        className = "stats_filter_class"
      >
        <div className="stats-activity__filter">
          <div className="tm-filter">
            <div className="tm-filter-sortby sort-by">
              <span className="filter-dd-lbl">
                <Text>
                  {<FormattedMessage id="CHOOSE_YEAR" />}
                </Text>
              </span>
              <Selectbox
                id="chooseYear"
                styleName="select-box-common filter-dropdown"
                options={getYearsForKPI()}
                value={this.state.completeSelectedYear}
                onChange={value => {
                  this.onSelectChange(value);
                }}
              />
            </div>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer
        styling={{
          backgroundColor: 'white',
          paddingAllSides: 'low',
          mobileOffset: true
        }}
      >
      {this.state.selectedYear !== null ? <StatsActivity yearSelected={this.state.selectedYear}  /> : ''}
      </SectionContainer>
    </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  statsHeader: state.statsDetails.statsHeader
});

const mapDispatchToProps = dispatch => ({
  fetchStatsHeader: (farmId, yearSelection) => dispatch(StatsAction.fetchStatsHeader(farmId, yearSelection))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Stats)));