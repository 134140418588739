import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import { connect } from 'react-redux';

class GrassAnalysisListCard extends Component {

  render() {
    const { grass, key, disable, openGrassAnalysisModal } = this.props;
    const fields = grass.fields.map(field => (
      <li key={field.name}>{field.name}</li>
    ));
    const fieldsFeedMix = grass.feedMixList && grass.feedMixList.map(field => (
      <li key={field.fieldId}>{field.fieldName ? field.fieldName : ''}</li>
    ));
    const harvestFeedMix = grass.feedMixList && grass.feedMixList.map(field => (
      <li key={field.harvest}>{field.harvest === null ? '' : field.harvest}</li>
    ));
    const amountFeedMix = grass.feedMixList && grass.feedMixList.map(field => (
      <li key={field.amount}>{field.amount === null ? '' : field.amount}</li>
    ));
    const unitFeedMix = grass.feedMixList && grass.feedMixList.map(field => (
      <li key={field.unit}>{field.unit === null ? '' : field.unit}</li>
    ));
    let changedAnalysisDate = grass.analysisDate.split("-");
    let changedAnalysedDate = (grass.analysedDate && grass.analysedDate !== null) ? grass.analysedDate.split("-") : null;
    let changedAnalysedDateChanged = changedAnalysedDate !== null ? changedAnalysedDate[2]+"-"+changedAnalysedDate[1]+"-"+changedAnalysedDate[0] : '';
    return (
      <li className={`list-item full-width`} key={key}>
        <span
          className={`grass-list-item-link ${disable && 'disabled'}`}
          onClick={() => openGrassAnalysisModal(grass.qrCode) }
        >
          {grass.message !== null ?
          <div className="grass-details">
            <div className="grass-analysis-type-no-grass">
              <span className="item-lbl"><FormattedMessage id="ANALYSIS_TYPE" /></span>
              <span title={grass.analysisType} className="item-value ellipsis-text">
                <Emphasis size="large">{grass.analysisType === 'YIELD' ? <FormattedMessage id="ANALYSIS_TYPE_YIELD" /> : grass.analysisType === 'FEEDMIX' ? <FormattedMessage id="ANALYSIS_TYPE_FEEDMIX" /> : <FormattedMessage id="ANALYSIS_TYPE_TBD" />}</Emphasis>
              </span>
            </div>
            <div className="grass-titlecard-no-grass">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_QR_CODE" /></span>
              <span title={grass.qrCode} className="item-value ellipsis-text">
                <Emphasis size="large">{grass.qrCode}</Emphasis>
              </span>
            </div>
            <div className="grass-crop-date-no-grass">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_ANALYSIS_DATE" /></span>
              <span title={changedAnalysisDate[2]+"-"+changedAnalysisDate[1]+"-"+changedAnalysisDate[0]} className="item-value ellipsis-text">
                {changedAnalysisDate[2]+"-"+changedAnalysisDate[1]+"-"+changedAnalysisDate[0]}
              </span>
            </div>
            <div className="grass-crop-date-no-grass">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_ANALYSED_DATE" /></span>
              <span title={changedAnalysedDateChanged} className="item-value ellipsis-text">
                {changedAnalysedDateChanged}
              </span>
            </div>
            <div className="grass-crop-field-no-grass">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_FIELDS" /></span>
              <span title="fields" className="item-value ellipsis-text">
                {grass.analysisType === "YIELD" &&
                <div className="empty-field-sub-content">
                  <ul>
                    {fields}
                  </ul>
                </div>}
                {grass.analysisType === "GRASS" &&
                <div className="empty-field-sub-content">
                  <ul>
                    {grass.fieldName}
                  </ul>
                </div>}
                {grass.analysisType === "FEEDMIX" &&
                <div className="empty-field-sub-content">
                  <ul>
                    {fieldsFeedMix}
                  </ul>
                </div>}
              </span>
            </div>
            {grass.analysisType === "YIELD" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_HARVEST" /></span>
              <span title={grass.harvest} className="item-value ellipsis-text">
                {grass.harvest}
              </span>
            </div>}
            {grass.analysisType === "GRASS" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_HARVEST" /></span>
              <span title={grass.harvest} className="item-value ellipsis-text">
                {grass.harvest}
              </span>
            </div>}
            {grass.analysisType === "FEEDMIX" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_HARVEST" /></span>
              <span title="fields" className="item-value ellipsis-text">
                <div className="empty-field-sub-content">
                  <ul>
                    {harvestFeedMix}
                  </ul>
                </div>
              </span>
            </div>}
            {grass.analysisType === "YIELD" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl">
                <FormattedMessage id="WEIGHT" /> (<FormattedMessage id="ADD_ANALYSIS_KG" />)
              </span>
              <span title={grass.weight} className="item-value ellipsis-text">
                {grass.weight === null ? '' : grass.weight}
              </span>
            </div>}
            {grass.analysisType === "FEEDMIX" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_AMOUNT" /></span>
              <span title="fields" className="item-value ellipsis-text">
                <div className="empty-field-sub-content">
                  <ul>
                    {amountFeedMix}
                  </ul>
                </div>
              </span>
            </div>}
            {grass.analysisType === "FEEDMIX" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_UNIT" /></span>
              <span title="fields" className="item-value ellipsis-text">
                <div className="empty-field-sub-content">
                  <ul>
                    {unitFeedMix}
                  </ul>
                </div>
              </span>
            </div>}
            <div className="grass-messagecard">
              <span title={grass.message} className="grass-tile">
                {grass.message}
              </span>
            </div>
          </div>
          :
          <div>
          <div className="grass-details grass-details-border-bottom">
            <div className="grass-analysis-type-with-grass">
              <span className="item-lbl"><FormattedMessage id="ANALYSIS_TYPE" /></span>
              <span title={grass.analysisType} className="item-value ellipsis-text">
                <Emphasis size="normal">{grass.analysisType === 'YIELD' ? <FormattedMessage id="ANALYSIS_TYPE_YIELD" /> : grass.analysisType === 'GRASS' ? <FormattedMessage id="ANALYSIS_TYPE_TBD" /> : <FormattedMessage id="ANALYSIS_TYPE_FEEDMIX" />}</Emphasis>
              </span>
            </div>
            <div className="grass-titlecard-with-grass">
            <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_QR_CODE" /></span>
              <span title={grass.qrCode} className="item-value ellipsis-text">
                <Emphasis size="normal">{grass.qrCode}</Emphasis>
              </span>
            </div>
            <div className={`${grass.analysisType === "GRASS" ? "grass-crop-date-with-grass-feedmix" : "grass-crop-date-with-grass-feedmix"}`}>
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_ANALYSIS_DATE" /></span>
              <span title={changedAnalysisDate[2]+"-"+changedAnalysisDate[1]+"-"+changedAnalysisDate[0]} className="item-value ellipsis-text">
                {changedAnalysisDate[2]+"-"+changedAnalysisDate[1]+"-"+changedAnalysisDate[0]}
              </span>
            </div>
            <div className={`${grass.analysisType === "GRASS" ? "grass-crop-date-with-grass-feedmix" : "grass-crop-date-with-grass-feedmix"}`}>
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_ANALYSED_DATE" /></span>
              <span title={changedAnalysedDateChanged} className="item-value ellipsis-text">
                {changedAnalysedDateChanged}
              </span>
            </div>
            <div className={`${grass.analysisType === "FEEDMIX" ? "grass-crop-field1-with-grass" : "grass-crop-field-with-grass"}`}>
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_FIELDS" /></span>
              <span title="fields" className="item-value ellipsis-text">
                {grass.analysisType === "YIELD" &&
                <div className="empty-field-sub-content">
                  <ul>
                    {fields}
                  </ul>
                </div>}
                {grass.analysisType === "GRASS" &&
                <div className="empty-field-sub-content">
                  <ul>
                    {grass.fieldName}
                  </ul>
                </div>}
                {grass.analysisType === "FEEDMIX" &&
                <div className="empty-field-sub-content">
                  <ul>
                    {fieldsFeedMix}
                  </ul>
                </div>}
              </span>
            </div>
            {grass.analysisType === "YIELD" &&
            <div className="grass-crop-with-grass">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_HARVEST" /></span>
              <span title={grass.harvest} className="item-value ellipsis-text">
                {grass.harvest}
              </span>
            </div>}
            {grass.analysisType === "GRASS" &&
            <div className="grass-crop-last-with-grass">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_HARVEST" /></span>
              <span title={grass.harvest} className="item-value ellipsis-text">
                {grass.harvest}
              </span>
            </div>}
            {grass.analysisType === "FEEDMIX" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_HARVEST" /></span>
              <span title="fields" className="item-value ellipsis-text">
                <div className="empty-field-sub-content">
                  <ul>
                    {harvestFeedMix}
                  </ul>
                </div>
              </span>
            </div>}
            {/*grass.analysisType === "GRASS" &&
            <div className="grass-messagecard-no-top">
              <span title={grass.message} className="grass-tile ellipsis-text">
                <span className="fontWeightBold">Merknad: </span><span>Prøver med TS over 35 % <br/> får ikke OMD-verdi</span>
              </span>
          </div>*/}
            {grass.analysisType === "YIELD" &&
            <div className="grass-crop-last-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="WEIGHT" />&nbsp;(<FormattedMessage id="ADD_ANALYSIS_KG" />)
              </span>
              <span title={grass.weight} className="item-value ellipsis-text">
                {grass.weight === null ? '' : grass.weight}
              </span>
            </div>}
            {grass.analysisType === "FEEDMIX" &&
            <div className="grass-crop-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_AMOUNT" /></span>
              <span title="fields" className="item-value ellipsis-text">
                <div className="empty-field-sub-content">
                  <ul>
                    {amountFeedMix}
                  </ul>
                </div>
              </span>
            </div>}
            {grass.analysisType === "FEEDMIX" &&
            <div className="grass-crop-last-no-grass-feedmix">
              <span className="item-lbl"><FormattedMessage id="GRASS_ANALYSIS_FIELD_UNIT" /></span>
              <span title="fields" className="item-value ellipsis-text">
                <div className="empty-field-sub-content">
                  <ul>
                    {unitFeedMix}
                  </ul>
                </div>
              </span>
            </div>}
          </div>
          <div className="grass-details grass-details-border-top">
            <div className="grass-crop-details-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_DRY_MATTER" />&nbsp;<FormattedMessage id="GRASS_ANALYSIS_FIELD_DRY_MATTER_UNIT" />
              </span>
              <span title={grass.dryMatter} className="item-value ellipsis-text">
                {grass.dryMatter === null ? 'N/A' : grass.dryMatter}
              </span>
            </div>
            <div className="grass-crop-details-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_PROTEIN" />&nbsp;<FormattedMessage id="GRASS_ANALYSIS_FIELD_PROTEIN_UNIT" />
              </span>
              <span title={grass.protein} className="item-value ellipsis-text">
                {grass.protein === null ? 'N/A' : grass.protein}
              </span>
            </div>
            <div className="grass-crop-details-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_ASH" />&nbsp;<FormattedMessage id="GRASS_ANALYSIS_FIELD_ASH_UNIT" />
              </span>
              <span title={grass.ash} className="item-value ellipsis-text">
                {grass.ash === null ? 'N/A' : grass.ash}
              </span>
            </div>
            <div className="grass-crop-details-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_OMD_UNIT" />
              </span>
              <span title={grass.omd} className="item-value ellipsis-text">
                {grass.omd === null ? 'N/A' : grass.omd}
              </span>
            </div>
            <div className="grass-crop-details-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_NDF" />&nbsp;<FormattedMessage id="GRASS_ANALYSIS_FIELD_NDF_UNIT" />
              </span>
              <span title={grass.ndf} className="item-value ellipsis-text">
                {grass.ndf === null ? 'N/A' : grass.ndf}
              </span>
            </div>  
            {/*<div className="grass-crop-details-with-grass">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_SUGAR" />&nbsp;(<FormattedMessage id="GRASS_ANALYSIS_FIELD_SUGAR_UNIT" />)
              </span>
              <span title={grass.sugar} className="item-value ellipsis-text">
                {grass.sugar === null ? 'N/A' : grass.sugar}
              </span>
            </div>*/}
            <div className="grass-crop-details-with-grass-last">
              <span className="item-lbl">
                <FormattedMessage id="GRASS_ANALYSIS_FIELD_AMMONIA" />&nbsp;(<FormattedMessage id="GRASS_ANALYSIS_FIELD_AMMONIA_UNIT" />)
              </span>
              <span title={grass.ammonia} className="item-value ellipsis-text">
                {(grass.ammonia === null || grass.ammonia === "") ? 'N/A' : grass.ammonia}
              </span>
            </div>
          </div>
          {grass.labComment !== null &&
          <div className="grass-details grass-details-border">
            <div>
              <span className="item-value ellipsis-text">
                {grass.labComment}
              </span>
            </div>
          </div>}
          </div>}
        </span>
      </li>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GrassAnalysisListCard);

