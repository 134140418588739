import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';

import Header from 'containers/header';
import { updateProfileInfo, loginSuccess, clearUpdate } from 'actions/Login';
import requestProfileImg from 'actions/ProfilePicture';
import TextBoxControl from 'components/forms/TextBoxControl';
import SelectControl from 'components/forms/SelectBoxControl';
import { Namevalidation, PhoneValidation } from 'utils';
import {
  IMG_UPLOAD_FOLDER,
  LanguageOptions
} from 'constants/index';
import Loader from 'components/Loader';
import UserAgreement from 'components/UserAgreement';

class ProfileSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFile: null,
      imageFile: null
    };
  }

  componentDidMount = () => {
    const { clearMessages } = this.props;
    clearMessages();
    this.initializeForm();
  };

  componentDidUpdate = prevProps => {
    const { /*AWSLoginDetails, profileImgRequest,*/ userDetails, profileImage } = this.props;
    const { profileImgUpdate } = profileImage;
    if (prevProps.userDetails !== userDetails) {
      this.initializeForm();
    }
    if (
      prevProps.profileImage.profileImgUpdate !== profileImgUpdate &&
      profileImgUpdate?.isUpdated
    ) {
      /*profileImgRequest(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, userDetails.id);*/
    }
  };

  initializeForm = () => {
    const { initialize, userDetails } = this.props;
    if (userDetails.preferredLanguage) {
      initialize({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phone: userDetails.phone,
        password: '**********',
        profileImageLocation: userDetails.profileImageLocation,
        emailId: userDetails.emailId,
        preferredLanguage: {
          label: userDetails.preferredLanguage,
          value: userDetails.preferredLanguage
        }
      });
    } else {
      initialize({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phone: userDetails.phone,
        password: '***********',
        profileImageLocation: userDetails.profileImageLocation,
        emailId: userDetails.emailId
      });
    }
  };

  // Handle file change input file
  handleFileChange = event => {
    if (event.target.files.length > 0) {
      this.setState({
        uploadedFile: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0]
      });
    }
  };

  /**
   * Handle update
   */
  handleSubmit = values => {
    /*const { imageFile } = this.state;
    const { AWSLoginDetails, userDetails, profileImgRequest } = this.props;*/
    this.updateProfileData(values);
    /*if (imageFile) {
      profileImgRequest(AWSLoginDetails, IMG_UPLOAD_REQUEST, userDetails.id, null, imageFile);
    }*/
  };

  // to update profile information once the profile pic get uploaded.
  updateProfileData = values => {
    const { updateProfile, userDetails } = this.props;
    const preferredLanguage = values.preferredLanguage ? values.preferredLanguage.value : '';
    // call profile update action
    updateProfile(
      values.emailId,
      userDetails.id,
      values.firstName,
      values.lastName,
      values.phone,
      `${IMG_UPLOAD_FOLDER}${userDetails.id}`,
      preferredLanguage
    );
  };

  render() {
    const { handleSubmit, profileImage, intl, dirty, profileUpdate } = this.props;
    const { imageFile } = this.state;
    const { profileImgUpdate } = profileImage;
    const isLoading = profileUpdate?.isProfileUpdating || profileImgUpdate?.isImgUpdating;
    const isUpdated = imageFile
      ? profileUpdate?.isUpdated && profileImgUpdate?.isUpdated
      : profileUpdate?.isUpdated;
    return (
      <div className="main-content">
        <Header bcTitle={intl.formatMessage({ id: 'profileSettings' })} />
        <div className="profile-settings">
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="profile-settings--left">
              <Field
                name="firstName"
                label={intl.formatMessage({ id: 'firstName' })}
                component={TextBoxControl}
                controlId="firstName"
                type="text"
              />
              <Field
                name="lastName"
                label={intl.formatMessage({ id: 'lastName' })}
                component={TextBoxControl}
                controlId="lastName"
                type="text"
              />
              <Field
                name="phone"
                label={intl.formatMessage({ id: 'PHONE' })}
                component={TextBoxControl}
                controlId="phone"
                type="text"
              />
              <div className="full-width profile-align-field">
                <Field
                  name="emailId"
                  label={intl.formatMessage({ id: 'email' })}
                  component={TextBoxControl}
                  controlId="emailId"
                  type="text"
                  disabled
                />
              </div>
              <Field
                name="password"
                label={intl.formatMessage({ id: 'password' })}
                component={TextBoxControl}
                controlId="password"
                type="password"
                disabled
              />
              <Link to="/profilesettings/updatepassword" className="link update-pwd-link">
                {intl.formatMessage({ id: 'change' })}
              </Link>
              <Field
                name="preferredLanguage"
                controlId="preferredLanguage"
                label={intl.formatMessage({ id: 'language' })}
                disabled={false}
                styleName="select-box-common"
                component={SelectControl}
                options={LanguageOptions}
              />
              <div className="form-group padding-agreement-link">
                <UserAgreement 
                  display={false}
                />
              </div>
              {isUpdated && !dirty ? (
                <div className="sent-notify">
                  <i className="success-indicator" />
                  <span>{intl.formatMessage({ id: 'updated' })}</span>
                </div>
              ) : null}
              {(profileUpdate?.hasProfileUpdateErr || profileImgUpdate?.hasImgUpdateErr) &&
              !dirty ? (
                <ErrorText size="small">{intl.formatMessage({ id: 'COMMON_ERR_TXT' })}</ErrorText>
              ) : null}
            </div>
            {isLoading && <Loader styleName="profile-loader" />}
            <div className="btn-wapper full-width">
              <ActionButton disabled={isLoading} onClick={() => handleSubmit(this.handleSubmit)}>
                {intl.formatMessage({ id: 'update' })}
              </ActionButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
/**
 * Form validation
 * @param {emailId, password} values
 */
function validate(values, props) {
  const errors = {};
  const { intl } = props;
  if (!values.firstName || !values.firstName.trim()) {
    errors.firstName = intl.formatMessage({ id: 'required' });
  }
  if (values.firstName) {
    const result = Namevalidation(values.firstName);
    if (!result.valid) {
      errors.firstName = intl.formatMessage({ id: result.message });
    }
  }
  if (!values.lastName || !values.lastName.trim()) {
    errors.lastName = intl.formatMessage({ id: 'required' });
  }
  if (values.lastName) {
    const result = Namevalidation(values.lastName);
    if (!result.valid) {
      errors.lastName = intl.formatMessage({ id: result.message });
    }
  }
  if (values.phone && !PhoneValidation(values.phone)) {
    errors.phone = intl.formatMessage({ id: 'invalid' });
  }
  // If errors is empty, the form will submit
  return errors;
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  profileUpdate: state.userDetails.profileUpdate,
  /*AWSLoginDetails: state.AWSLogin.data,*/
  profileImage: state.profileImage
});

const mapDispatchToProps = dispatch => ({
  updateProfile: (emailId, id, firstName, lastName, phone, profileImageLocation, preferredLang) =>
    dispatch(
      updateProfileInfo(emailId, id, firstName, lastName, phone, profileImageLocation, preferredLang)
    ),
  profileImgRequest: (awsLogin, type, userId, obj, file, callback) =>
    dispatch(requestProfileImg(awsLogin, type, userId, obj, file, callback)),
  updateUserInfo: data => dispatch(loginSuccess(data)),
  clearMessages: () => dispatch(clearUpdate())
});

export default injectIntl(
  reduxForm({
    form: 'ProfileUpdateForm',
    validate
  })(connect(mapStateToProps, mapDispatchToProps)(ProfileSetting))
);
