import * as React from 'react';

import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import HistoricalList from 'mui-components/fields/fieldtab/historical/HistoricalList';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
 
class Historical extends React.Component {

  //render method
  render() {
    return (
    <div className="full-width main-content">
      <SectionContainer
        styling={{
          backgroundColor: 'white',
          paddingAllSides: 'low',
          mobileOffset: true
        }}
        className = "stats_header_class2"
      >
        <HistoricalList />
      </SectionContainer>
    </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(Historical)));