import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import ModalDialog from '@tine/lib-frontend-components/components/dialogs/modal-dialog';
import { CrossIcon } from '@tine/lib-frontend-components/elements/icons';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Loader from 'components/Loader';
import FieldDraw from 'mui-components/fields/fieldtab/FieldDraw';
import mixpanel from 'mixpanel-browser';
import { EanaCreateMap } from 'utils/map';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import { ADD_FIELD, EDIT_FIELD, FIELD_CREATED, FIELD_UPDATED } from 'constants/MixPanel';
import {
  getDropDownFieldsStatuses,
  StringifyToPolygonArrFormat
} from 'utils/index';
import * as fieldActions from 'actions/Fields';
import { ADMIN, OWNER, ADVISOR, NEW } from 'constants/index';

import { Box, Grid, Stack, Button } from '@mui/material'
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiTextArea from 'components/mui-forms/MuiTextArea';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FieldMap = new EanaCreateMap();
class EditFieldsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditFieldSizePrompt: false,
      sizeChangeWarningAcepted: false,
      isShowFullScreen: false,
      selectedFieldId: null,
      showPrevNextPopup: false,
      disableGrain: false,
      saveButtonClick: false,
      fieldName: '',
      fieldSize: '',
      fieldCrop: 0,
      fieldNote: ''
    };
  }

  componentDidMount = () => {
    const { userDetails, paramId } = this.props;
    this.initializeValues();
    if(paramId === NEW) {
      mixpanel.identify(userDetails.id);
      mixpanel.track(ADD_FIELD);
    } else {
      mixpanel.identify(userDetails.id);
      mixpanel.track(EDIT_FIELD);
    }
  };

  componentDidUpdate(prevProps) {
    const { fieldDetails, mapCoords, selectedField } = this.props;
    const { coOrds, area } = mapCoords;
    if (prevProps.selectedField !== selectedField) {
      this.initializeValues();
    }
    if (coOrds?.length > 0 && !fieldDetails.isLoading && prevProps.mapCoords.area !== area) {
      this.setState({
        fieldSize: area
      });
      this.clearDrawError();
    }
  }

  roundToTwoDecimal = num => {    
    return Number(Math.round(num+'e2')+'e-2');
  }

  /**
   * To initialize values in form
   */
  initializeValues = () => {
    const { selectedField, fieldMasterData, paramId, fieldCoords } = this.props;
    const { coOrds } = fieldCoords;    
    const [fieldCropName] = (selectedField.cropId !== undefined && selectedField.cropId !== null) ? 
      (fieldMasterData.cropList ? _.filter(fieldMasterData.cropList, ['id', selectedField.cropId]) : '') : (fieldMasterData.cropList ? _.filter(fieldMasterData.cropList, ['id', 0]) : '') ;
    const fieldArea = FieldMap.getFieldSize(coOrds ? JSON.stringify(StringifyToPolygonArrFormat(coOrds)) : null);
    this.setState({
      fieldName: selectedField.fieldName,
      fieldSize: selectedField.fieldSize > 0 ? selectedField.fieldSize : fieldArea,
      fieldCrop: fieldCropName && paramId !== NEW ? selectedField.cropId : '',
      fieldNote: selectedField.note !== null ? selectedField.note : ''
    })
  };

  clearDrawError = () => {
    const {
      mapCoords: { coOrds }
    } = this.props;
    if (!coOrds || coOrds?.length < 3) {
      this.setState({ mapError: true });
    } else {
      this.setState({ mapError: false });
    }
  };

  handleSubmit = values => {
    if(this.state.saveButtonClick) {
      const { addUpdateField, mapCoords, activeFarm, selectedField, userDetails, paramId } = this.props;
      const { coOrds, area } = mapCoords;
      const fieldValues = selectedField;
      this.setState({
        showPrevNextPopup: false
      });

      fieldValues.fieldName = this.state.fieldName;
      fieldValues.cropId = this.state.fieldCrop;
      fieldValues.fieldSize = (this.state.fieldSize).replace(',','.') || area;
      fieldValues.note = this.state.fieldNote !== '' ? this.state.fieldNote : null;
      // return false if there were no fields boundary drawn
      if (!coOrds || coOrds?.length < 3) {
        this.clearDrawError();
        return;
      }
      fieldValues.gpsPoints = coOrds;
      this.setState({ sizeChangeWarningAcepted: false });
      if(paramId === NEW) {
        mixpanel.identify(userDetails.id);
        mixpanel.track(FIELD_CREATED);
      } else {
        mixpanel.identify(userDetails.id);
        mixpanel.track(FIELD_UPDATED);
      }
      addUpdateField(fieldValues, activeFarm.farm.id);
      this.props.setStateOfParent(fieldValues); 
    } else {
      const { mapCoords, selectedField, paramId, currentFieldValue, history } = this.props;
      const { selectedFieldId } = this.state;
      const { coOrds, area } = mapCoords;
      const fieldValues = selectedField;

      fieldValues.fieldName = this.state.fieldName;
      fieldValues.cropId = this.state.fieldCrop;
      fieldValues.fieldSize = this.state.fieldSize || area;
      fieldValues.note = this.state.fieldNote !== '' ? this.state.fieldNote : null;
      fieldValues.gpsPoints = coOrds;
      if(selectedFieldId !== null && selectedFieldId !== paramId) {
        if(JSON.stringify(currentFieldValue) === JSON.stringify(fieldValues)) {
          this.props.setStateOfParent(null);
          history.push(`/managefields/${selectedFieldId}`);
          return;
        } else {
          this.setState({
            showPrevNextPopup: true
          });
          return;
        }
      }
    }
  };

  /**
   * change handler for size input
   *
   */
  onSizeFieldChange = (e) => {
    this.setState({ fieldSize: e.target.value });
    const {
      props: {
        mapCoords: { coOrds },
        activeFarm,
        hasSupportAdminRole
      },
      state: { sizeChangeWarningAcepted }
    } = this;

    if (
      coOrds &&
      coOrds.length > 0 &&
      !sizeChangeWarningAcepted &&
      (activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data)
    ) {
      this.setState({ showEditFieldSizePrompt: true, sizeChangeWarningAcepted: true });
    }
  };

  /**
   * To show/hide prompt on editing size field
   *
   */
  showDialogFieldSize = action => {
    const { mapCoords } = this.props;
    const { area } = mapCoords;
    if (action) {
      this.setState({
        showEditFieldSizePrompt: false,
        sizeChangeWarningAcepted: true,
        fieldSize: this.state.fieldSize
      });
    } else {
      this.setState({
        showEditFieldSizePrompt: false,
        sizeChangeWarningAcepted: false,
        fieldSize: area
      });
    }
  };

  // To toggle full screen boundary drawing
  toggleScreen = () => {
    this.setState(prevState => ({
      isShowFullScreen: !prevState.isShowFullScreen
    }));
  };

  changeField = (fieldId) => {
    const { fieldDetails } = this.props;
    this.setState({
      selectedFieldId: fieldId
    });
    fieldDetails.errorMessage = null;
    fieldDetails.isSuccess = false;
    fieldDetails.hasAddUpdateError = false;
  }

  saveChangeField = () => {
    const { selectedFieldId } = this.state;
    const { fieldDetails, fetchFieldList, activeFarm, history } = this.props;
    fetchFieldList(activeFarm.farm.id);
    if(selectedFieldId !== null) {
      this.props.setStateOfParent(null);
      history.push(`/managefields/${selectedFieldId}`);
    }
    fieldDetails.errorMessage = null;
    fieldDetails.isSuccess = false;
    fieldDetails.hasAddUpdateError = false;
    this.setState({
      saveButtonClick: false
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const {
      handleSubmit,
      fieldDetails,
      intl,
      activeFarm,
      selectedField,
      paramId,
      pageContent,
      lblCommon,
      fieldMasterData,
      hasSupportAdminRole,
      history,
      previousIndex,
      nextIndex
    } = this.props;
    const { errorMessage, isLoading, isSuccess, hasAddUpdateError } = fieldDetails;
    const {
      mapError,
      showEditFieldSizePrompt,
      isShowFullScreen,
      showPrevNextPopup,
      selectedFieldId
    } = this.state;
    const disableCreateFieldButton = (activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data) && 
      this.state.fieldName !== '' && this.state.fieldSize !== '' && this.state.fieldSize !== 0;
    const lblPage = pageContent[0];
    const centerAlignStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '12vh',
      zIndex: 5
    };
    const customButtonStyle = {
      backgroundColor: '#00696D', color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00696D'
      }, borderRadius: '100px', paddingLeft: 20, paddingRight: 20, textTransform: 'none', fontSize: '14px', fontFamily: 'Inter'
    };
    const customButtonStyleDisabled = {
      backgroundColor: '#CCCCCC', color: '#ffffff', borderRadius: '100px', paddingLeft: 20, paddingRight: 20, textTransform: 'none', fontSize: '14px', fontFamily: 'Inter'
    };
    const linkStyle = { color: '#00696D', fontSize: '14px', fontFamily: 'Inter', textTransform: 'none' };
    const linkStyleDisabled = { color: '#cccccc', fontSize: '14px', fontFamily: 'Inter', textTransform: 'none' };
    const linkContainerStyle = { display: 'flex', alignItems: 'center' };
    const arrowStyle = { color: '#00696D', width: '18px', height: '18px' };
    const arrowStyleLeftDisabled = { color: '#cccccc', width: '18px', height: '18px', marginRight: 10 };
    const arrowStyleRightDisabled = { ...arrowStyleLeftDisabled, marginLeft: 10 };
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="edit-field-container">
          <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', flexGrow: 1 }}>
            <Grid container justifyContent="flex-end">
              <Grid item md={5} className='grid-with-padding' sx={{paddingRight: '20px'}}>
                <Stack className='text-with-padding'>
                  <MuiTextBox 
                    name="fieldName"
                    id="fieldName"
                    label={lblPage.labels.fieldName}
                    variant="outlined"
                    value={this.state.fieldName}
                    onChange={this.handleChange}
                    showFullWidth="true"
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiSelectBox 
                    name="fieldCrop"
                    id="fieldCrop"
                    label={lblPage.labels.crop}
                    variant="outlined"
                    value={this.state.fieldCrop}
                    options={getDropDownFieldsStatuses(fieldMasterData.cropList)}
                    onChange={this.handleChange}
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiTextBox 
                    name="fieldSize"
                    id="fieldSize"
                    label={lblPage.labels.area}
                    variant="outlined"
                    value={this.state.fieldSize}
                    onChange={e => this.onSizeFieldChange(e)}
                    cssStyle="width: 50%"
                    InputLabelProps={{ shrink: lblPage.labels.area }}
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiTextArea 
                    name="fieldNote"
                    id="fieldNote"
                    label={lblCommon.note}
                    variant="outlined"
                    rows={12}
                    value={this.state.fieldNote}
                    onChange={this.handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item md={7}>
                <div className="field-area">
                  {selectedField && !isShowFullScreen && paramId !== NEW && (
                    <FieldDraw
                      title="Full screen"
                      selectedField={selectedField}
                      mapId="off-screen-map"
                      paramId={paramId}
                      toggleScreen={() => this.toggleScreen()}
                    />
                  )}
                  {selectedField && !isShowFullScreen && paramId === NEW && (
                    <FieldDraw
                      title="Full screen"
                      selectedField={selectedField}
                      mapId="off-screen-map-new"
                      paramId={paramId}
                      toggleScreen={() => this.toggleScreen()}
                    />
                  )}
                  {isShowFullScreen && (
                    <ModalDialog
                      show={isShowFullScreen}
                      onClose={() => this.toggleScreen()}
                      width={{
                        mobile: '95%',
                        tablet: '95%',
                        web: '95%'
                      }}
                      height={{
                        mobile: '95%',
                        tablet: '95%',
                        web: '95%'
                      }}
                      closeIcon={
                        <span className="close-modal">
                          <CrossIcon scale={0.5} />
                        </span>
                      }
                    >
                      <FieldDraw
                        title="close full screen"
                        selectedField={selectedField}
                        mapId="full-screen-map"
                        paramId={paramId}
                        fullScreen
                        toggleScreen={this.toggleScreen}
                      />
                    </ModalDialog>
                  )}
                  {showPrevNextPopup && (
                    <ModalDialog
                    minWidth={{mobile: '70%', tablet: '30%', web: '25%'}}
                    width={{mobile: '70%', tablet: '35%', web: '25%'}}
                    height={{mobile: '25%', tablet: '25%', web: '25%'}}
                    show={showPrevNextPopup}
                    closeIcon=""
                    onClose={() => {}}>
                    <div className="text-align-center">
                      <div>
                        <div className='fertilizer-form-style'>
                          <div>
                            <Text>{intl.formatMessage({ id: 'FIELD_NEXT_PAGE' })}</Text>
                          </div>
                        </div>
                        <div className='fertilizer-form-style-button'>
                          <span className="button-style-padding-right">
                            <ActionButton 
                              onClick={() => {
                              this.setState({
                                saveButtonClick: true
                              });
                            }}>{intl.formatMessage({ id: 'YES' })}</ActionButton>
                          </span>
                          <span>
                            <ActionButton multi 
                              onClick={() => {
                              this.setState({
                                showPrevNextPopup: false
                              });
                              history.push(`/managefields/${selectedFieldId}`);
                            }}>{intl.formatMessage({ id: 'NO' })}</ActionButton>
                          </span>
                        </div>
                      </div>
                    </div>
                  </ModalDialog>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
          {isLoading ? <Loader styleName={isLoading ? 'field-loader' : ''} /> : null}
          {isSuccess && this.saveChangeField()}
          {isSuccess && paramId === NEW && <Redirect to="/managefields" />}
          {showEditFieldSizePrompt && (
            <ConfirmDialogBox
              heading={intl.formatMessage({ id: 'FIELD_SIZE_EDIT_PROMPT_HEADING' })}
              body={intl.formatMessage({ id: 'FIELD_SIZE_EDIT_PROMPT' })}
              confirmText="FIELD_SIZE_EDIT_PROMPT_CONFIRM"
              cancelText="CANCEL"
              callback={action => this.showDialogFieldSize(action)}
              warning={false}
            />
          )}
        </div>
        <div>
          <p className="validation-map">
            {mapError && (
              <ErrorText>{intl.formatMessage({ id: 'FIELDS_BOUNDARY_ERROR_MSG' })}</ErrorText>
            )}
          </p>
          {hasAddUpdateError ? (
            <div className="updated-div">
              <span>
                <ErrorText>
                  {errorMessage?.status === 400
                    ? errorMessage?.data
                    : errorMessage?.status === 409
                    ? intl.formatMessage({ id: 'FIAE' })
                    : intl.formatMessage({ id: 'COMMON_ERR_TXT' })}
                </ErrorText>
              </span>
            </div>
          ) : null}

          {isSuccess ? (
            <div className="updated-div">
              <i className="success-indicator" />
              <span>
                <FormattedMessage id="updated" />
              </span>
            </div>
          ) : null}
        </div>
        <div style={centerAlignStyle}  className="fixed-button-mui">
          {previousIndex !== null ? 
          (paramId !== NEW ? <div style={linkContainerStyle}>
            <ArrowBackIcon style={arrowStyle} />
            <Button style={linkStyle} onClick={() => this.changeField(previousIndex)} type="submit">
              {intl.formatMessage({ id: 'PREVIOUS_FIELD' })}
            </Button>
          </div> : <div style={linkContainerStyle}>&nbsp;</div>) :
          (paramId !== NEW ? <div style={linkContainerStyle} sx={{disabled: true}}>
            <ArrowBackIcon style={arrowStyleLeftDisabled} />
            <span style={linkStyleDisabled}>{intl.formatMessage({ id: 'PREVIOUS_FIELD' })}</span>
          </div> : <div style={linkContainerStyle}>&nbsp;</div>)}
          <Button style={!disableCreateFieldButton ? customButtonStyleDisabled : customButtonStyle} disabled={isLoading || !disableCreateFieldButton} type="submit" 
            onClick={() => {
              this.setState({
                saveButtonClick: true
              });
              handleSubmit(this.handleSubmit)
            }}
          >
            {intl.formatMessage({ id: 'AEF_SB' })}
          </Button>
          {nextIndex !== null ?
          (paramId !== NEW ? <div style={linkContainerStyle}>
            <Button style={linkStyle} onClick={() => this.changeField(nextIndex)} type="submit">
              {intl.formatMessage({ id: 'NEXT_FIELD' })}
            </Button>
            <ArrowForwardIcon style={arrowStyle} />
          </div> : <div style={linkContainerStyle}>&nbsp;</div>) : 
          (paramId !== NEW ? <div style={linkContainerStyle} sx={{disabled: true}}>
            <ArrowForwardIcon style={arrowStyleRightDisabled} />
            <span style={linkStyleDisabled}>{intl.formatMessage({ id: 'NEXT_FIELD' })}</span>
          </div> : <div style={linkContainerStyle}>&nbsp;</div>)}
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  fieldDetails: state.fieldDetails,
  activeFarm: state.farmList.activeFarm,
  mapCoords: state.fieldDetails.savedFieldCoordinates,
  fieldFormValues: state.form.FieldsForm,
  lblCommon: state.masterData.data[0]?.commonLabels,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'fieldPage']),
  userDetails: state.userDetails,
  fieldCoords: state.fieldDetails.savedFieldCoordinates,
  hasSupportAdminRole: state.SupportAdminRole
});

function mapDispatchToProps(dispatch) {
  return {
    addUpdateField: (data, farmId) => dispatch(fieldActions.addUpdateField(data, farmId)),
    fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
  };
}

export default injectIntl(
  reduxForm({
    form: 'FieldsForm'
  })(connect(mapStateToProps, mapDispatchToProps)(EditFieldsForm))
);
