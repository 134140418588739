import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import ManageUsers from 'mui-components/settings/users/ManageUsers';
import FarmSettings from 'mui-components/settings/farms/FarmSettings';
import ManageVehicles from 'mui-components/settings/vehicles/ManageVehicles';
import ManageEquipment from 'mui-components/settings/equipments/ManageEquipments';
import { CREATE_FARM, VEHICLE, EQUIPMENT, USERS } from 'constants/index';
import { Box, Tabs, Tab, Grid, Typography, Stack, Chip } from '@mui/material'
import { createSvgIcon } from '@mui/material/utils';
import { pageview } from 'utils/GoogleAnalytics';
import { checkPermissions } from 'utils/index';
import mixpanel from 'mixpanel-browser';
import { ADD_VEHICLE, ADD_EQUIPMENT, ADD_FARM } from 'constants/MixPanel';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index === 0 ? 'customFieldTabDiv' : 'customFertilizerTabDiv'}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-4 -4 32 32"
    strokeWidth={1.5}
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
  </svg>,
  'Plus',
);

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      value: 0,
      showAddFarmDialog: false,
      addNewFarm: false,
      showAddVehicleDialog: false,
      showAddEquipmentDialog: false,
      vehicleSelectedId: 'new',
      equipmentSelectedId: 'new'
    };
    this.onOpenFarmDialog = this.onOpenFarmDialog.bind(this);
    this.onCloseFarmDialog = this.onCloseFarmDialog.bind(this);
    this.addNewFarmDialog = this.addNewFarmDialog.bind(this);
    this.onOpenVehicleDialog = this.onOpenVehicleDialog.bind(this);
    this.onCloseVehicleDialog = this.onCloseVehicleDialog.bind(this);
    this.onOpenEquipmentDialog = this.onOpenEquipmentDialog.bind(this);
    this.onCloseEquipmentDialog = this.onCloseEquipmentDialog.bind(this);
  }

  componentDidMount = () => {
    const { location } = this.props;
    if (location.pathname.includes(USERS)) {
      this.setState({
        selected: 'MANAGE_USER',
        value: 1
      });
    } else if (location.pathname.includes(VEHICLE)) {
      this.setState({
        selected: 'MANAGE_VEHICLES',
        value: 2
      });
    } else if (location.pathname.includes(EQUIPMENT)) {
      this.setState({
        selected: 'MANAGE_EQUIPMENT',
        value: 3
      });
    } else {
      this.setState({
        selected: 'FARM_SETTING',
        value: 0
      });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history } = this.props;    
    if(newValue === 3) {
      this.setState({ selected: 'MANAGE_EQUIPMENT', value: newValue });
      history.push("/settings/equipment");
    } else if(newValue === 2) {
      this.setState({ selected: 'MANAGE_VEHICLES', value: newValue });
      history.push("/settings/vehicle");
    }  else if(newValue === 1) {
      this.setState({ selected: 'MANAGE_USER', value: newValue });
      history.push("/settings/users");
    } else {
      this.setState({ selected: 'FARM_SETTING', value: newValue });
      history.push("/settings");
    }
    // Capture GA pageview events for tag change
    const settingPaths = [
      '/settings',
      '/settings/users',
      '/settings/vehicle',
      '/settings/equipment'
    ];
    pageview(settingPaths[newValue]);
  };

  onOpenFarmDialog = () => {
    this.setState({
      showAddFarmDialog: true,
      addNewFarm: false
    });
  }

  onCloseFarmDialog = () => {
    this.setState({ showAddFarmDialog: false });
  }

  onOpenVehicleDialog = (value) => {
    this.setState({
      vehicleSelectedId: value,
      showAddVehicleDialog: true
    });
  }

  onCloseVehicleDialog = () => {
    this.setState({
      vehicleSelectedId: 'new',
      showAddVehicleDialog: false
    });
  }

  onOpenEquipmentDialog = (value) => {
    this.setState({
      equipmentSelectedId: value,
      showAddEquipmentDialog: true
    });
  }

  onCloseEquipmentDialog = () => {
    this.setState({
      equipmentSelectedId: 'new',
      showAddEquipmentDialog: false
    });
  }

  addNewFarmDialog = () => {
    const { userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_FARM);
    this.setState({
      showAddFarmDialog: true,
      addNewFarm: true
    });
  }

  render() {
    const { selected, value } = this.state;
    const { intl, activeFarm, rolePermissions, userDetails, hasSupportAdminRole } = this.props;
    let disableAdd = false;
    let enableAssetButton = false;
    const tabStyle = { 
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      color: '#00696D',
      textTransform: 'none',
      padding: '0px 40px'
    };
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    if (activeFarm) {
      disableAdd = checkPermissions(CREATE_FARM, activeFarm, rolePermissions);
      enableAssetButton = (activeFarm.role ? (activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR) : hasSupportAdminRole.data);
    }
    if (!selected) {
      return null;
    }
    return (
      <div className="main-content">
        <Header
          bcTitle={intl.formatMessage({ id: selected })}
          bcLink={{ text: intl.formatMessage({ id: 'SETTINGS' }), to: '/settings' }}
        />
        <div className="settings-tabs">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1 }}>
            <Grid container justifyContent="flex-end">
              <Grid item md={value === 0 || value === 2 || value === 3 ? 8 : 12}>
                <Tabs variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  value={value} onChange={this.handleChange} className='mui-tabs'>
                  <Tab style={tabStyle} className={`${!activeFarm ? 'disable' : ''}`} label={intl.formatMessage({ id: 'FARM_SETTING' })} {...a11yProps(0)} />
                  <Tab style={tabStyle} className={`${!activeFarm ? 'disable' : ''}`} label={intl.formatMessage({ id: 'MANAGE_USER' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                  <Tab style={tabStyle} className={`${!activeFarm ? 'disable' : ''}`} label={intl.formatMessage({ id: 'MANAGE_VEHICLES' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                  <Tab style={tabStyle} className={`${!activeFarm ? 'disable' : ''}`} label={intl.formatMessage({ id: 'MANAGE_EQUIPMENT' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                </Tabs>
              </Grid>
              {value === 0 &&
              (<Grid item md={4} className='grid-padding-top' sx={{ paddingRight: "30px" }}>
                <Stack direction="row" justifyContent="end">
                  <Chip
                    icon={<PlusIcon />}
                    label={intl.formatMessage({ id: 'MU_ANF' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => this.addNewFarmDialog()}
                    className={`${!disableAdd ? 'disable' : ''}`}
                    style={buttonStyle}
                  />
                </Stack>
              </Grid>)}
              {value === 2 && enableAssetButton &&
              (<Grid item md={4} className='grid-padding-top' sx={{ paddingRight: "30px" }}>
                <Stack direction="row" justifyContent="end">
                  <Chip
                    icon={<PlusIcon />}
                    label={intl.formatMessage({ id: 'ADD_VEHICLE' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ADD_VEHICLE); this.onOpenVehicleDialog('new');}}
                    className={`${!disableAdd ? 'disable' : ''}`}
                    style={buttonStyle}
                  />
                </Stack>
              </Grid>)}
              {value === 3 && enableAssetButton && 
              (<Grid item md={4} className='grid-padding-top' sx={{ paddingRight: "30px" }}>
                <Stack direction="row" justifyContent="end">
                  <Chip
                    icon={<PlusIcon />}
                    label={intl.formatMessage({ id: 'ADD_EQUIPMENT' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => {mixpanel.identify(userDetails.data.id); mixpanel.track(ADD_EQUIPMENT); this.onOpenEquipmentDialog('new');}}
                    className={`${!disableAdd ? 'disable' : ''}`}
                    style={buttonStyle}
                  />
                </Stack>
              </Grid>)}
            </Grid>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <FarmSettings
              showAddFarmDialog={this.state.showAddFarmDialog}
              addNewFarm={this.state.addNewFarm}
              onOpenFarmDialog={this.onOpenFarmDialog}
              onCloseFarmDialog={this.onCloseFarmDialog}
              addNewFarmDialog={this.addNewFarmDialog}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ManageUsers />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ManageVehicles
              showAddVehicleDialog={this.state.showAddVehicleDialog}
              onOpenVehicleDialog={this.onOpenVehicleDialog}
              onCloseVehicleDialog={this.onCloseVehicleDialog}
              vehicleSelectedId={this.state.vehicleSelectedId}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <ManageEquipment
              showAddEquipmentDialog={this.state.showAddEquipmentDialog}
              onOpenEquipmentDialog={this.onOpenEquipmentDialog}
              onCloseEquipmentDialog={this.onCloseEquipmentDialog}
              equipmentSelectedId={this.state.equipmentSelectedId}
            />
          </CustomTabPanel>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  farmList: state.farmList,
  rolePermissions: state.RolesDetails,
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails,
  hasSupportAdminRole: state.SupportAdminRole
});
export default injectIntl(withRouter(connect(mapStateToProps)(Settings)));