import { API_ENDPOINT } from 'constants/ApiUrl';
import { 
  REGULATION_LIST_LOADING, 
  REGULATION_LIST_FAILURE, 
  REGULATION_LIST_SUCCESS,
  REGULATION_LIST_SAVE_LOADING, 
  REGULATION_LIST_SAVE_FAILURE, 
  REGULATION_LIST_SAVE_SUCCESS
} from 'constants/store/Regulations';
import { GET, POST } from 'constants/index';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

/**
 * Get all the regulation list
 */
export const regulationList = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: REGULATION_LIST_LOADING, payload: true });
    const { preferredLanguage } = getState().userDetails?.data;
    await dispatch(
      apiRequest({
        url: `${API_ENDPOINT}regulation/user/regulations/${userId}?language=${GetLanguageCode(
          preferredLanguage
        )}`,
        method: GET
      })
    )
      .then(response => {
        dispatch({ type: REGULATION_LIST_SUCCESS, payload: response.data });
      })
      .catch(error => {
        dispatch({ type: REGULATION_LIST_FAILURE, payload: error });
      });
    } catch (error) {
      dispatch({ type: REGULATION_LIST_FAILURE, payload: error });
    }
};

 export const updateRegulationList = (data) => async (dispatch, getState) => {
  dispatch({ type: REGULATION_LIST_SAVE_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${API_ENDPOINT}/regulation/save/user/regulation?language=${GetLanguageCode(preferredLanguage)}`,
      method: POST,
      data
    })
  )
    .then(response => {
      dispatch({ type: REGULATION_LIST_SAVE_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: REGULATION_LIST_SAVE_FAILURE, payload: error });
    });
};