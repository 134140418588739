import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import AppContainer from '@tine/lib-frontend-components/components/alignment/app-container';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import Footer from 'components/Footer';
import EanaWebPageWrapper from '@tine/lib-frontend-components/components/eana/eana-web-page-wrapper';
import EanaWebBody from '@tine/lib-frontend-components/components/eana/eana-web-body';
import EanaWebGridLayout from '@tine/lib-frontend-components/components/eana/eana-web-grid-layout';
import NavigationBar from 'components/NavigationBar';
import Loader from 'components/Loader';
import LoaderPage from 'components/LoaderPage';
import Header from 'containers/header';
import CommonError from 'components/CommonErrorMessage';

import { fetchRolePermistionList } from 'actions/RolesDetails';
import fetchMasterData from 'actions/MasterData';
import { fetchFarmList } from 'actions/Farms';
import { dataValidationPermission } from 'actions/GpsData';
import { fetchSupportAdminPermission } from 'actions/SupportAdminRole';

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndCondition: false,
      fetchFarmListState: false
    };
    this.openTermsAndCondition = this.openTermsAndCondition.bind(this);
    this.closeTermsAndCondition = this.closeTermsAndCondition.bind(this);
  }
  componentDidMount = () => {
    const { getRolesDetails, userDetails, dataPermission, getMasterData, supportAdminPermission } = this.props;
    const { idToken } = userDetails.data;
    getRolesDetails(idToken);
    dataPermission(idToken);
    supportAdminPermission();
    getMasterData();
  };

  componentDidUpdate = () => {
    const { getFarmList, userDetails, hasSupportAdminRole, farmList } = this.props;
    const { lastaccessedFarm } = userDetails.data;
    const { fetchFarmListState } = this.state;
    let version = "v1";
    if(!fetchFarmListState && hasSupportAdminRole.data !== null) {
      if(hasSupportAdminRole.data) {
        version = "v2";
      }
      if(!farmList.data) {
        if(localStorage.getItem("selectedFarmId") === null &&  localStorage.getItem("selectedFarmName") === null) {
          getFarmList(lastaccessedFarm,version);
        } else {
          getFarmList(localStorage.getItem("selectedFarmId"),version);
        }
        this.setState({
          fetchFarmListState: true
        });
      }
    }
  }

  openTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: true
    });
  }

  closeTermsAndCondition = () => {
    this.setState ({
      showTermsAndCondition: false
    });
  }

  render() {
    const { showTermsAndCondition } = this.state;
    const {
      props: { children, farmList, rolesDetails, userDetails, location, masterData, intl }
    } = this;
    const error = farmList.hasError || rolesDetails.hasError || masterData.hasError;
    const profileDisplayCheck = location && location.pathname.includes('profilesettings');
    return (
      <AppContainer>
        <EanaWebBody>
          <EanaWebPageWrapper>
            {userDetails.isLogoutLoading ? <LoaderPage /> : null}
            <EanaWebGridLayout>
              <FlexGridItem>
                <NavigationBar />
              </FlexGridItem>
              <FlexGridItem>
                <div className="full-width app-content-wrapper">
                  {(farmList.data && rolesDetails.data && masterData.data) || profileDisplayCheck
                    ? children
                    : null}
                  {!farmList.data && !rolesDetails.data && !masterData.data && !error && (
                    <Loader styleName="layout-loader" />
                  )}
                  {error && !profileDisplayCheck && (
                    <div className="full-width main-content">
                      <Header
                        bcLink={{ to: '/tasks', text: intl.formatMessage({ id: 'TASKS' }) }}
                        bcTitle={intl.formatMessage({ id: 'HEADER_TASKS' })}
                      />
                      <CommonError />
                    </div>
                  )}
                  <Footer
                    showTermsAndCondition={showTermsAndCondition}
                    openTermsAndCondition={this.openTermsAndCondition}
                    closeTermsAndCondition={this.closeTermsAndCondition}
                    userDetails={userDetails}
                  />
                </div>
              </FlexGridItem>
            </EanaWebGridLayout>
          </EanaWebPageWrapper>
        </EanaWebBody>
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  rolesDetails: state.RolesDetails,
  farmList: state.farmList,
  masterData: state.masterData,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  getFarmList: (lastaccessedFarm, version) => dispatch(fetchFarmList(lastaccessedFarm, version)),
  dataPermission: token => dispatch(dataValidationPermission(token)),
  getRolesDetails: token => dispatch(fetchRolePermistionList(token)),
  getMasterData: () => dispatch(fetchMasterData()),
  supportAdminPermission: () => dispatch(fetchSupportAdminPermission())
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout)));
