/**
 * Data validation index component has tab module to switch between the settings componenets
 */
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import TabBar from '@tine/lib-frontend-components/components/navigation/tab-bar';
import SectionContainer from '@tine/lib-frontend-components/components/alignment/section-container';
import AutoplacementGridSection from '@tine/lib-frontend-components/components/alignment/autoplacement-grid-section';
import FlexGridItem from '@tine/lib-frontend-components/components/alignment/flex-grid-item';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Colors from '@tine/lib-frontend-components/styles/colors';
import Selectbox from 'components/forms/Selectbox';
import GpsData from 'containers/datavalidation/GpsData';
import SoundData from 'containers/datavalidation/SoundData';
import Header from 'containers/header';
import { fetchGpsFarmList } from 'actions/GpsData';
import Loader from 'components/Loader';
import { API_ENDPOINT } from 'constants/index';

class DataValidation extends Component {
  state = {
    selected: 'GPS data',
    tabIndex: 0,
    isFieldsLoading: false,
    isTaskLoading: false,
    fields: [],
    tasks: [],
    fieldsHasError: false,
    taskHasError: false,
    fieldError: {},
    taskError: {},
    selectedField: '',
    selectedTask: ''
  };

  componentDidMount = () => {
    const { userDetails, getFarmList } = this.props;
    getFarmList(userDetails.idToken);
  };

  // get farm option
  farmOption = () => {
    const { gpsData } = this.props;
    return gpsData.farmList.map(data => ({ value: data.id, label: data.name }));
  };

  // get field option
  fieldOption = () => {
    const { fields } = this.state;
    return fields.map(data => ({ value: data.fieldId, label: data.fieldName }));
  };

  // taskOption
  taskOption = () => {
    const { tasks } = this.state;
    if (tasks.length > 0) {
      return tasks.map(data => ({
        value: data.task.id,
        label: data.task.activity ? data.task.activity.activityName : 'Undefined'
      }));
    }
    return [];
  };

  // on tab change passing page title to the header
  onTabChange = index => {
    const tabList = ['GPS data', 'Sound data'];
    this.setState({ selected: tabList[index], tabIndex: index });
  };

  // on farm change
  onChangeFarmOptions = item => {
    const { userDetails } = this.props;
    this.setState({
      selectedField: null,
      selectedTask: null,
      tasks: [],
      fields: [],
      isFieldsLoading: true,
      fieldError: false
    });

    try {
      axios(`${API_ENDPOINT}farms/${item.value}/fields`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: userDetails.idToken
        }
      })
        .then(response => {
          this.setState({
            fields: response.data,
            isFieldsLoading: false
          });
        })
        .catch(error => {
          this.setState({
            fieldsHasError: true,
            fieldError: error,
            isFieldsLoading: false
          });
        });
    } catch (error) {
      this.setState({
        fieldsHasError: true,
        fieldError: error,
        isFieldsLoading: false
      });
    }
  };

  // on farm change
  onChangeFieldOptions = item => {
    const { userDetails } = this.props;
    this.setState({
      selectedTask: null,
      selectedField: item,
      tasks: [],
      isTaskLoading: true,
      taskHasError: false
    });

    try {
      axios(`${API_ENDPOINT}tasks/gpslocations/${item.value}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: userDetails.idToken
        }
      })
        .then(response => {
          this.setState({
            tasks: response.data,
            isTaskLoading: false
          });
        })
        .catch(error => {
          this.setState({
            taskHasError: true,
            taskError: error,
            isTaskLoading: false
          });
        });
    } catch (error) {
      this.setState({
        taskHasError: true,
        taskError: error,
        isTaskLoading: false
      });
    }
  };

  // on farm change
  onChangeTaskOptions = value => {
    this.setState({
      selectedTask: value
    });
  };

  renderContent = index => {
    const { tasks, selectedTask } = this.state;
    return [
      <GpsData tasks={tasks} selectedTask={selectedTask} />,
      <SoundData tasks={tasks} selectedTask={selectedTask} />
    ][index];
  };

  render() {
    const {
      selected,
      tabIndex,
      selectedField,
      selectedTask,
      fieldError,
      fieldsHasError,
      taskHasError,
      taskError,
      isFieldsLoading,
      isTaskLoading
    } = this.state;
    const { gpsData } = this.props;
    return (
      <div className="full-width main-content">
        <Header bcTitle={selected} />
        {!gpsData || gpsData.isLoading ? <Loader styleName="validator-loader" /> : null}
        {gpsData && !gpsData.isPermissionLoading && gpsData.hasPermission ? null : (
          <Redirect to="/managefields" />
        )}
        {gpsData.hasError && (
          <ErrorText size="small">
            We didn&#39;t manage to return the data from the system. Please try again later.
          </ErrorText>
        )}
        {gpsData?.farmList?.length > 0 ? (
          <SectionContainer
            styling={{
              paddingAllSides: 'none'
            }}
          >
            <AutoplacementGridSection className="option-selection">
              <FlexGridItem>
                <div className="dv-lbl">
                  <Text color={Colors.black} size="normal">
                    Select farm
                  </Text>
                </div>
                <Selectbox
                  options={this.farmOption()}
                  styleName="select-box-common"
                  placeholder=""
                  onChange={v => this.onChangeFarmOptions(v)}
                />
              </FlexGridItem>
              <FlexGridItem>
                <div className="dv-lbl">
                  <Text color={Colors.black} size="normal">
                    Select Field
                  </Text>
                </div>
                <Selectbox
                  options={this.fieldOption()}
                  value={selectedField}
                  styleName="select-box-common"
                  placeholder=""
                  onChange={v => this.onChangeFieldOptions(v)}
                />
                {fieldsHasError ? <Text color={Colors.negative}>{fieldError.message}</Text> : null}
              </FlexGridItem>
              <FlexGridItem>
                <div className="dv-lbl">
                  <Text color={Colors.black} size="normal">
                    Select task
                  </Text>
                </div>
                <Selectbox
                  options={this.taskOption()}
                  value={selectedTask}
                  placeholder=""
                  styleName="select-box-common"
                  onChange={v => this.onChangeTaskOptions(v)}
                />
                {taskHasError ? <Text color={Colors.negative}>{taskError.message}</Text> : null}
              </FlexGridItem>
            </AutoplacementGridSection>
            {isTaskLoading || isFieldsLoading ? <Loader styleName="validator-loader" /> : null}
            <div className="output-block">
              <SectionContainer
                styling={{
                  paddingAllSides: 'none',
                  paddingLeft: 'low',
                  paddingRight: 'low'
                }}
              >
                <TabBar
                  initialTab="gps"
                  secondary
                  onTabChange={(index, key) => this.onTabChange(index, key)}
                  tabBarIdentifier="tab-bar-1"
                  tabs={[
                    { title: 'Gps data', key: 'gps' },
                    { title: 'Sound data', key: 'sound' }
                  ]}
                />
              </SectionContainer>
              {this.renderContent(tabIndex)}
            </div>
          </SectionContainer>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  gpsData: state.GpsData,
  farmList: state.farmList
});

const mapDispatchToProps = dispatch => ({
  getFarmList: token => dispatch(fetchGpsFarmList(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataValidation));
