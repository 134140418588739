import * as React from 'react';
import { getDropDownMineralFertilizers } from 'utils/index';
import { Dialog, DialogTitle, Chip } from '@mui/material'
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';

const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px', background: 'transparent' };
const buttonFilledStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#ffffff', padding: '10px', backgroundColor: '#00696D' };

const MineralFertilizerUpdateModal = ({
  editMineralFertilizer,
  editMineralFertilizerValue,
  closeEditMineralFertilizer,
  mineralFertilizer,
  setMineralFertilizerState,
  saveMineralFertilizer,
  addNewMineralFertilizer,
  intl
}) => (
  <div>
    <Dialog onClose={() => {}} open={editMineralFertilizer}>
      <DialogTitle sx={{ padding: "25px" }}>
        {editMineralFertilizerValue === 'add' ? intl.formatMessage({ id: 'ADD_MINERAL_FERTILIZER' }) : intl.formatMessage({ id: 'UPDATE_MINERAL_FERTILIZER' })}
      </DialogTitle>
      <div style={{padding: "15px"}}>
        <MuiSelectBox 
          name="updateMineralFertilizer"
          label={intl.formatMessage({ id: 'SELECT_MINERAL_FERTILIZER' })}
          controlId="updateMineralFertilizer"
          onChange={e => setMineralFertilizerState(e)}
          sx={{ backgroundColor: "#ffffff", maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis' }}
          options={getDropDownMineralFertilizers(mineralFertilizer.getMineralFertilizerList)}
        />
      </div>
      <div style={{padding: "25px"}}>
        <span className="padding-left-class-low">
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            variant="outlined"
            clickable
            style={buttonStyle}
            onClick={closeEditMineralFertilizer}
            sx={{paddingRight: "40px"}}
          /> 
          <span style={{padding: "0px 5px"}}>&nbsp;</span>
          {editMineralFertilizerValue === 'add' ?
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={buttonFilledStyle}
            onClick={addNewMineralFertilizer}
          /> :
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={buttonFilledStyle}
            onClick={saveMineralFertilizer}
          />}
        </span>
      </div>
    </Dialog>
  </div>
);

export default MineralFertilizerUpdateModal;