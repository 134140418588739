import * as React from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Card, Box, Typography, CardContent, Stack, Link } from '@mui/material'
import { ErrorGreenIcon, EditIcon } from 'components/mui-forms/MuiIcons';
 
class WeatherHeader extends React.Component {
  render() {
    const titleText = {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    }
    const titleSubText = {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#3F4949',
      paddingTop: '10px'
    }
    const titleSubText1 = {
      ...titleSubText,
      fontSize: '14px',
      fontWeight: '800',
      letterSpacing: '0.25px',
      color: '#041F21',
      textAlign: 'center',
      paddingTop: '0px'
    }
    const titleSubText2 = {
      ...titleSubText,
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#041F21',
      paddingTop: '0px'
    }
    const titleLink = {
      ...titleText,
      color:'#00696D',
      textAlign: 'center',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    }
    const paddingLeftRight = {
      ...titleText,
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "40px"
    }
    const paddingLeftRight1 = {
      ...titleText,
      paddingLeft: "20px",
      paddingRight: "20px"
    }
    const numberTextBig = {
      ...titleText,
      fontSize: '57px',
      lineHeight: '64px',
      letterSpacing: '-0.25px'
    }
    const numberTextBig1 = {
      ...titleText,
      fontSize: '18px',
      lineHeight: '64px',
      letterSpacing: '-0.25px'
    }
    const numberTextSmall = {
      ...titleText,
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '-0.25px',
      paddingTop: '3px',
      paddingLeft: '50px'
    }
    const numberTextSmall2 = {
      ...titleText,
      fontSize: '18px',
      lineHeight: '40px',
      letterSpacing: '-0.25px',
      paddingTop: '3px',
      paddingLeft: '50px'
    }
    const numberTextSmall1 = {
      ...titleText,
      fontSize: '24px',
      lineHeight: '40px',
      fontWeight: '800',
      letterSpacing: '-0.25px'
    }
    const paddingLeftBig = {
      ...titleText,
      paddingLeft: '50px'
    }
    const subTitleText = {
      ...titleText,
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      fontWeight: 600,
    }
    const { intl, fieldChart, openOmdDialogBox } = this.props;
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Card variant="outlined" sx={{boxShadow: '1px 2px #cccccc'}}>
              <CardContent>
                <Stack>
                  <Link underline="none">
                    <Typography variant="body1" sx={{cursor: 'pointer'}} style={titleLink}>
                      <Box component="span" sx={{ '&:hover': { backgroundColor: 'transparent' }, '&:selected': { backgroundColor: 'transparent' }}}>
                        <ErrorGreenIcon sx={{ verticalAlign: 'top' }} />
                      </Box>
                      <Box component="span" sx={{ paddingLeft: '10px' }}>
                        <FormattedMessage id="WEATHER_VERDIER" />
                      </Box>
                    </Typography>
                  </Link>
                </Stack>
                <Typography style={titleText} sx={{paddingLeft: '20px'}}>
                  I dag 
                </Typography>
                <Grid container spacing={2} sx={{alignItems: 'center'}}>
                  <Grid item xs={12} md={10}>
                    <Stack sx={{alignItems: 'center'}}>
                      <Typography variant="body1" sx={{textAlign: 'center', display: 'flex'}}>
                        <Box component="span" sx={{paddingTop: '20px'}}>
                        {fieldChart.weatherData && fieldChart.weatherData.currentOMD && fieldChart.weatherData.currentOMD !== null ?
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextBig}>
                            {fieldChart.weatherData.currentOMD+'%'}
                          </Typography> :
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextBig1}>-&nbsp;</Typography>}
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={titleText}>
                            Fordøyelighet av organisk materiale
                          </Typography>
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={titleText}>
                            OMD
                          </Typography>
                        </Box>
                        <Box component="span" style={paddingLeftRight} sx={{paddingTop: '20px'}}>
                          {fieldChart.weatherData && fieldChart.weatherData.currentTempSum && fieldChart.weatherData.currentTempSum !== null ?
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextSmall}>
                             {fieldChart.weatherData.currentTempSum+'°'}
                          </Typography> :
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextSmall2}>-&nbsp;</Typography>}
                          <Typography variant="body1" sx={{textAlign: 'center'}} style={paddingLeftBig}>
                            Varmesum
                          </Typography>
                        </Box>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={2} sx={{ borderLeft: '1px solid #cccccc'}}>
                    <Box component="span" style={paddingLeftRight}>
                      {fieldChart.weatherData && fieldChart.weatherData.daysToOMDGoal && fieldChart.weatherData.daysToOMDGoal !== null ?
                      <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextBig}>
                        {fieldChart.weatherData.daysToOMDGoal}
                      </Typography> : 
                      <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextBig1}>-&nbsp;</Typography>}
                      <Typography variant="body1" sx={{textAlign: 'center'}} style={titleText}>
                        Dager til høstevindu
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/*<Grid item xs={12} md={5}>
            <Card variant="outlined">
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Word of the Day
                </Typography>
              </CardContent>
            </Card>
          </Grid>*/}
        </Grid>
        {fieldChart && fieldChart.isLoading ? '' :
          <Grid container spacing={2} sx={{paddingTop: '20px'}}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{boxShadow: '1px 2px #cccccc'}}>
                <CardContent>
                  <Stack>
                    <Typography variant="body1" sx={{display: 'flex'}}>
                      <Box component="span" sx={{width: '70%'}}>
                        <Typography variant="body1" style={subTitleText}>
                          {intl.formatMessage({ id: 'WEATHER_HEADER_HEADING1' })}
                        </Typography>
                        <Typography variant="body1" style={titleSubText}>
                          {fieldChart.weatherData && fieldChart.weatherData.biomassYara && fieldChart.weatherData.biomassYara.lastUpdatedDate !== null ? fieldChart.weatherData.biomassYara.lastUpdatedDate : ''}
                        </Typography>
                      </Box>
                      <Box component="span" style={paddingLeftRight1}>
                        <Typography variant="body1" sx={{textAlign: 'center'}} style={numberTextSmall1}>
                        {fieldChart.weatherData && fieldChart.weatherData.biomassYara && fieldChart.weatherData.biomassYara.estimatedYield !== null ? fieldChart.weatherData.biomassYara.estimatedYield : 'N/A'}
                        </Typography>
                        <Typography variant="body1" style={titleSubText1}>
                          {intl.formatMessage({ id: 'WEATHER_HEADER_HEADING1_UNIT' })}
                        </Typography>
                      </Box>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="body1" sx={{paddingTop: '28px'}}>
                <img src="images/yara.png" alt="Yara" width="90" height="68" />
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="body1" style={titleSubText2} sx={{textAlign: 'right', paddingRight: '10px'}}>
                {fieldChart.omdData !== null ?
                  <Box component="span">
                    {fieldChart.omdData && fieldChart.omdData.goal}
                  </Box>
                  :
                  <Box component="span">
                    {fieldChart.weatherData && fieldChart.weatherData.omdGoal && fieldChart.weatherData.omdGoal.goal}
                  </Box>
                }
                <Box onClick={openOmdDialogBox} component="span" sx={{paddingLeft: '10px', paddingTop: '10px', verticalAlign: 'middle', cursor: 'pointer'}}>
                  <EditIcon />
                </Box>
              </Typography>
            </Grid>
          </Grid>
        }
      </Box>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});

export default injectIntl(withRouter(connect(mapStateToProps)(WeatherHeader)));