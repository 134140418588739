import React from 'react';
import { FormControl, InputLabel, Select, OutlinedInput, Checkbox, ListItemText } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';

const MuiMultiSelect = ({
  label,
  options,
  onChange,
  value
}) => {
  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={undefined || value}
        onChange={onChange}
        input={<OutlinedInput label={label} />}
        renderValue={(value) => value.join(', ')}
        sx={{
          width: '170px',
          float: 'right',
          color: '#6F7979',
          '.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#6F7979'
            }
          },
          '.MuiSelect-select': {
            '&:focus': {
              borderColor: '#6F7979'
            }
          }
        }}
      >
        {options !== null && options !== undefined && options.map((option) => (
          <MenuItem key={option} value={option} style={{ border: 'none', backgroundColor: 'transparent' }}>
            <ListItemText primary={option} />
            <Checkbox checked={value.indexOf(option) > -1} sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }, }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MuiMultiSelect;
