import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field, reset } from 'redux-form';
import { connect } from 'react-redux';
import Header from 'containers/header';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import NavigationButton from '@tine/lib-frontend-components/components/buttons/navigation-button';
import TextBoxControl from 'components/forms/TextBoxControl';
import { PasswordValidation } from 'utils/index';
import { updatePassword } from 'actions/ForgotPassword';
import Loader from 'components/Loader';

class UpdatePassword extends Component {
  state = {
    isLoading: false,
    hasError: false,
    isDone: false
  };

  /**
   * Handle farm submit
   */
  handleSubmit = values => {
    const { updatePasswordSubmit, userDetails, resetFarm } = this.props;
    this.setState({ isLoading: true, isDone: false, hasError: false });
    // call change password action
    updatePasswordSubmit(
      userDetails.emailId,
      values.oldPassword,
      values.newPassword,
      userDetails.accessToken,
      response => {
        if (response.status === 200) {
          this.setState({ isLoading: false, isDone: true });
          resetFarm();
        } else {
          resetFarm();
          this.setState({
            isLoading: false,
            hasError: true
          });
        }
      }
    );
  };

  render() {
    const { handleSubmit, dirty, intl } = this.props;
    const { isDone, hasError, isLoading } = this.state;
    return (
      <div className="main-content">
        <Header
          bcTitle={intl.formatMessage({ id: 'changePassword' })}
          bcLink={{ to: '/profileSettings', text: intl.formatMessage({ id: 'profileSettings' }) }}
        />
        <div className="update-password">
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <div className="btn-wapper">
              <NavigationButton disabled={isLoading} route="/profilesettings" multi>
                {intl.formatMessage({ id: 'cancel' })}
              </NavigationButton>
            </div>
            <div className="full-width pwd-align">
              <Field
                name="oldPassword"
                label={intl.formatMessage({ id: 'YOUR_PWD' })}
                component={TextBoxControl}
                controlId="oldPassword"
                type="password"
              />
            </div>
            <div className="full-width change-pwd-block">
              <div className="data-field">
                <Field
                  name="newPassword"
                  label={intl.formatMessage({ id: 'newPassword' })}
                  component={TextBoxControl}
                  controlId="newPassword"
                  type="password"
                />
                <Field
                  name="repeatPassword"
                  label={intl.formatMessage({ id: 'repeatPassword' })}
                  component={TextBoxControl}
                  controlId="repeatPassword"
                  type="password"
                />
              </div>
              <div className="validation-text">
                <p className="top-align">
                  <FormattedMessage id="pwdValidateRule1" />
                </p>
                <p>
                  <FormattedMessage id="pwdValidateRule2" />
                </p>
                <p>
                  <FormattedMessage id="pwdValidateRule3" />
                </p>
                <p>
                  <FormattedMessage id="pwdValidateRule4" />
                </p>
              </div>
            </div>
            {isDone && !dirty ? (
              <div className="sent-notify">
                <i className="success-indicator" />
                <span>
                  <FormattedMessage id="passwordUpdated" />
                </span>
              </div>
            ) : null}
            {hasError && !dirty ? (
              <p className="error-text">{intl.formatMessage({ id: 'COMMON_ERR_TXT' })}</p>
            ) : null}
            {isLoading ? <Loader styleName="profile-loader" /> : null}
            <div className="btn-wapper">
              <ActionButton disabled={isLoading} onClick={() => handleSubmit(this.handleSubmit)}>
                {intl.formatMessage({ id: 'create' })}
              </ActionButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  const { intl } = props;
  if (!values.newPassword || !values.newPassword.trim()) {
    errors.newPassword = intl.formatMessage({ id: 'required' });
  }
  if (!values.oldPassword || !values.oldPassword.trim()) {
    errors.oldPassword = intl.formatMessage({ id: 'required' });
  }
  if (!values.repeatPassword || !values.repeatPassword.trim()) {
    errors.repeatPassword = intl.formatMessage({ id: 'required' });
  }
  if (values.newPassword && !PasswordValidation(values.newPassword)) {
    errors.newPassword = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.newPassword && values.newPassword.trim().length < 8) {
    errors.newPassword = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.newPassword && values.newPassword.trim().length > 30) {
    errors.newPassword = intl.formatMessage({ id: 'PWD_MUST' });
  }
  if (values.newPassword !== values.repeatPassword) {
    errors.repeatPassword = intl.formatMessage({ id: 'PWD_MATCH' });
  }
  // If errors is empty, the form will submit
  return errors;
}

const mapStateToProps = state => ({ userDetails: state.userDetails.data });
const mapDispatchToProps = dispatch => ({
  updatePasswordSubmit: (emailId, oldPassword, newPassword, accessToken, callback) =>
    dispatch(updatePassword(emailId, oldPassword, newPassword, accessToken, callback)),
  resetFarm: () => dispatch(reset('UpdatePasswordForm'))
});

export default injectIntl(
  reduxForm({
    form: 'UpdatePasswordForm',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdatePassword)))
);
