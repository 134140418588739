import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import GrassAnalysisRegister from 'containers/grassanalysis/GrassAnalysisRegister';
import { AddIcon } from '@tine/lib-frontend-components/elements/icons';
import GrassAnalysisListCard from 'containers/grassanalysis/GrassAnalysisListCard';
import { CssGridLayout } from '@tine/lib-frontend-components/components/alignment/css-grid-layout/css-grid-layout';
import * as grassAnalysisActions from 'actions/GrassAnalysis';
import { formatDateValue } from 'utils/index';
import { ADMIN, OWNER, ADVISOR } from 'constants/index';
import * as fieldActions from 'actions/Fields';
import mixpanel from 'mixpanel-browser';
import { ADD_GRASS_ANALYSIS, GRASS_ANALYSIS_COMPLETE } from 'constants/MixPanel';
import _ from 'lodash';
import TrashcanIcon from '@tine/lib-frontend-components/elements/icons/trashcan-icon/index';
import SelectControl from 'components/forms/SelectBoxControl';
import TextBoxControl from 'components/forms/TextBoxControl';
import { buttonRoundStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import { Stack, Box, Chip } from '@mui/material';
import { PlusIcon } from 'components/mui-forms/MuiIcons';

class GrassAnalysisList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGrassAnalysisModal: false,
      isCreate: true,
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      noHarvestError: false,
      noWeightParameter: false,
      analysisDate: new Date(),
      selectedQrCode: null,
      showDelete: false,
      qrCode: null,
      qrCodeDisabled: false,
      selectedGrassAnalysis: null,
      feedMixArr: [
        {
          fieldId: '',
          fieldName: '',
          harvest: null,
          amount: '',
          unit: '',
          id: ''
        }
      ],
      showYieldAnalysis: true,
      showFeedMixAnalysis: false,
      showTbdAnalysis: false,
      selectedYearDropdown: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderFeedmixAmountSection = this.renderFeedmixAmountSection.bind(this);
    this.fieldOptions = this.fieldOptions.bind(this);
    this.analysisTypeOptions = this.analysisTypeOptions.bind(this);
    this.analysisTypeOptions2 = this.analysisTypeOptions2.bind(this);
    this.harvestOptions = this.harvestOptions.bind(this);
    this.onSelectHarvest = this.onSelectHarvest.bind(this);
    this.openGrassAnalysisModal = this.openGrassAnalysisModal.bind(this);
    this.closeGrassAnalysisModal = this.closeGrassAnalysisModal.bind(this);
    this.showDialog = this.showDialog.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.handleAnalysisTypeChange = this.handleAnalysisTypeChange.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
  }

  componentDidMount = () => {
    const { history, activeFarm, getGrassAnalysis, fetchFieldList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    } else {
      getGrassAnalysis(activeFarm.farm.id);
      fetchFieldList(activeFarm.farm.id);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { history, activeFarm, getGrassAnalysis, fetchFieldList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      getGrassAnalysis(activeFarm.farm.id);
      fetchFieldList(activeFarm.farm.id);
    }
  };

  updateInitialize = (selectedGrassAnalysis) => {
    const { initialize, intl } = this.props;
    let fields = [];
    let keyPair = {};
    if (selectedGrassAnalysis[0].fields) {
      selectedGrassAnalysis[0].fields.map(data => (
        //eslint-disable-next-line
        keyPair = {},
        keyPair["value"] = data.id,
        keyPair["label"] = data.name,
        fields.push(keyPair)
      ));
    }
    if (selectedGrassAnalysis[0].year) {
      keyPair = {};
      keyPair["value"] = selectedGrassAnalysis[0].year;
      keyPair["label"] = selectedGrassAnalysis[0].year;
      this.setState({
        selectedYearDropdown: keyPair
      });
    }
    if (selectedGrassAnalysis[0].analysisType === "GRASS") {
      if(selectedGrassAnalysis[0].fieldId !== null) {
        //eslint-disable-next-line
        keyPair = {},
        keyPair["value"] = selectedGrassAnalysis[0].fieldId,
        keyPair["label"] = selectedGrassAnalysis[0].fieldName,
        fields.push(keyPair)
      }
    }
    let analyse = [];
    let keyPair1 = {};
    if (selectedGrassAnalysis[0].analysisType === "YIELD") {
      keyPair1 = {};
      keyPair1["value"] = "YIELD";
      keyPair1["label"] = intl.formatMessage({ id: 'ANALYSIS_TYPE_YIELD' });
      analyse.push(keyPair1);
    } else if (selectedGrassAnalysis[0].analysisType === "FEEDMIX") {
      keyPair1 = {};
      keyPair1["value"] = "FEEDMIX";
      keyPair1["label"] = intl.formatMessage({ id: 'ANALYSIS_TYPE_FEEDMIX' });
      analyse.push(keyPair1);
    } else {
      keyPair1 = {};
      keyPair1["value"] = "GRASS";
      keyPair1["label"] = intl.formatMessage({ id: 'ANALYSIS_TYPE_TBD' });
      analyse.push(keyPair1);
    }
    const formValues = {
      qrCode: selectedGrassAnalysis != null ? selectedGrassAnalysis[0].qrCode : '',
      weight: selectedGrassAnalysis != null ? selectedGrassAnalysis[0].weight : '',
      field: fields,
      analysisType: analyse
    };
    if(selectedGrassAnalysis != null && selectedGrassAnalysis[0].analysisType === "FEEDMIX") {
      this.setState({
        showYieldAnalysis: false,
        showFeedMixAnalysis: true,
        showTbdAnalysis: false
      });
      let chosenFeedMixArr = [];
      chosenFeedMixArr = selectedGrassAnalysis[0].feedMixList && selectedGrassAnalysis[0].feedMixList !== null && selectedGrassAnalysis[0].feedMixList.map((item, index) => ({
        fieldId: item.fieldId,
        fieldName: item.fieldName ? item.fieldName : '',
        harvest: item.harvest,
        amount: item.amount && item.amount !== null ? item.amount : '',
        unit: item.unit,
        id: index
      }));
      if(chosenFeedMixArr.length > 0) {
        this.setState({ feedMixArr: chosenFeedMixArr });
      }
    }
    if(selectedGrassAnalysis != null && selectedGrassAnalysis[0].analysisType === "GRASS") {
      this.setState({
        showYieldAnalysis: false,
        showFeedMixAnalysis: false,
        showTbdAnalysis: true
      });
    }
    this.setState ({
      analysisDate: selectedGrassAnalysis != null ? new Date(selectedGrassAnalysis[0].analysisDate) : new Date(),
      qrCodeDisabled: true
    });
    if(selectedGrassAnalysis[0].harvest === 1) {
      this.setState({
        showHarvest1: true,
        showHarvest2: false,
        showHarvest3: false,
        showHarvest4: false
      });
    } else if(selectedGrassAnalysis[0].harvest === 2) {
      this.setState({
        showHarvest1: false,
        showHarvest2: true,
        showHarvest3: false,
        showHarvest4: false
      });
    } else if(selectedGrassAnalysis[0].harvest === 3) {
      this.setState({
        showHarvest1: false,
        showHarvest2: false,
        showHarvest3: true,
        showHarvest4: false
      });
    } else if(selectedGrassAnalysis[0].harvest === 4){
      this.setState({
        showHarvest1: false,
        showHarvest2: false,
        showHarvest3: false,
        showHarvest4: true
      });
    } else {
      this.setState({
        showHarvest1: false,
        showHarvest2: false,
        showHarvest3: false,
        showHarvest4: false
      });
    }
    initialize(formValues);
  }

  componentDidUpdate(prevProps) {
    const { history, activeFarm, getGrassAnalysis, fetchFieldList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push('/grassanalysis');
      getGrassAnalysis(activeFarm.farm.id);
      fetchFieldList(activeFarm.farm.id);
    }
  }

  /**
   * To add another feedmix and feedmix amount
   */
  addFeedMixArrSection = () => {
    const { feedMixArr } = this.state;
    const newObj = {
      ...feedMixArr[0],
      id: feedMixArr[feedMixArr.length - 1].id + 1,
      fieldId: '',
      fieldName: '',
      harvest: '',
      amount: '',
      unit: '',
    };
    this.setState({
      feedMixArr: feedMixArr.concat([newObj])
    });
  };

  /**
   * To remove selected feedmix and feedmix amount
   */
  removeFeedMixArrSection = (e, pestItem) => {
    const { feedMixArr } = this.state;
    this.setState({
      feedMixArr: feedMixArr.filter(pestObj => pestItem.id !== pestObj.id)
    });
  };

  /**
   * change hanlder to edit feedmix field
   */
  onFeedMixFieldChange = (option, pestItem) => {
    const { feedMixArr } = this.state;
    const resultArr = feedMixArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.fieldId = option.value;
        targetObj.fieldName = option.label;
        return targetObj;
      }
      return item;
    });
    this.setState({ feedMixArr: resultArr });
  };

  /**
   * change hanlder to edit feedmix harvest
   */
  onFeedMixHarvestChange = (option, pestItem) => {
    const { feedMixArr } = this.state;
    const resultArr = feedMixArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.harvest = option.value;
        return targetObj;
      }
      return item;
    });
    this.setState({ feedMixArr: resultArr });
  };

  /**
   * change hanlder to edit feedmix unit
   */
  onFeedMixUnitChange = (option, pestItem) => {
    const { feedMixArr } = this.state;
    const resultArr = feedMixArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.unit = option.value;
        return targetObj;
      }
      return item;
    });
    this.setState({ feedMixArr: resultArr });
  };

  /**
   * change handler to edit amount
   */
  onFeedMixAmountChange = (event, pestItem) => {
    const { feedMixArr } = this.state;
    const resultArr = feedMixArr.map(item => {
      if (item.id === pestItem.id) {
        const targetObj = item;
        targetObj.amount = event.target.value;
        return targetObj;
      }
      return item;
    });
    this.setState({ feedMixArr: resultArr });
  };

  /**
   * adding feed mix sections dynamically
   */
  renderFeedmixAmountSection = () => {
    const { feedMixArr } = this.state;
    const { intl } = this.props;
    return (
      <div className="multiple-feedmix-section">
        <div className="multiple-feedmix-sub-section">
        {feedMixArr && feedMixArr !== null && feedMixArr.map((item, index) => (
           <div className="feed-mix-flex" key={item.id}>
              <div className="select-box-common padding-top-css-text padding-top-bottom">
                <Field
                  styleName="select-box-small-margin"
                  placeholder={intl.formatMessage({ id: 'ANALYSIS_TYPE_FIELD' })}
                  options={this.fieldOptionsFeed("qrCode")}
                  component={SelectControl}
                  name={`field_${item.id}`}
                  onChange={option => this.onFeedMixFieldChange(option, item)}
                  label={intl.formatMessage({ id: 'ANALYSIS_TYPE_FIELD' })}
                  controlledValue={
                    item.fieldId ? { label: item.fieldName, value: item.fieldId } : null
                  }
                />
              </div>
              <div className="select-box-common select-box-common2 padding-top-css-text padding-top-bottom">
                <Field
                  styleName="select-box-small-margin"
                  placeholder={intl.formatMessage({ id: 'ANALYSIS_TYPE_HARVEST' })}
                  options={this.harvestOptions("qrCode")}
                  component={SelectControl}
                  name={`harvest_${item.id}`}
                  onChange={option => this.onFeedMixHarvestChange(option, item)}
                  label={intl.formatMessage({ id: 'ANALYSIS_TYPE_HARVEST' })}
                  controlledValue={
                    item.harvest ? { label: item.harvest, value: item.harvest } : null
                  }
                />
              </div>
              <div className="select-box-amount select-box-common1 padding-top-css-text padding-top-bottom">
                <Field
                  name={`amount_${item.id}`}
                  styleName="select-box-amount-top"
                  label={intl.formatMessage({ id: 'ANALYSIS_TYPE_AMOUNT' })}
                  component={TextBoxControl}
                  type="text"
                  onChange={option => this.onFeedMixAmountChange(option, item)}
                  controlledValue={item.amount && item.amount !== null ? item.amount : ''}
                />
              </div>
              <div className="select-box-common select-box-common1 padding-top-css-text padding-top-bottom">
                <Field
                  styleName="select-box-small-margin"
                  placeholder={intl.formatMessage({ id: 'ANALYSIS_TYPE_UNIT' })}
                  options={this.unitOptions("qrCode")}
                  component={SelectControl}
                  name={`unit_${item.id}`}
                  onChange={option => this.onFeedMixUnitChange(option, item)}
                  label={intl.formatMessage({ id: 'ANALYSIS_TYPE_UNIT' })}
                  controlledValue={
                    item.unit ? { label: item.unit, value: item.unit } : null
                  }
                />
              </div>
              <div className="pesticide-section-controls">
                {feedMixArr.length !== 1 && (
                  <div
                    className="delete-icon"
                    onClick={e => this.removeFeedMixArrSection(e, item)}
                    onKeyPress={e => this.removeFeedMixArrSection(e, item)}
                    role="button"
                    tabIndex={-1}
                  >
                    <TrashcanIcon scale={1} />
                  </div>
                )}
                {(feedMixArr.length === 1 || index === feedMixArr.length - 1) && feedMixArr.length < 6 && (
                  <div
                    className="add-icon"
                    onClick={this.addFeedMixArrSection}
                    onKeyPress={this.addFeedMixArrSection}
                    role="button"
                    tabIndex={-1}
                  >
                    <AddIcon scale={1} />
                  </div>
                )}
              </div>
            </div>
        ))}
        </div>
      </div>
    );
  };

  confirmDelete(qrCode) {
    this.setState({
      showDelete: true,
      qrCode: qrCode
    })
  }

  showDialog = action => {
    const { deleteGrassAnalysis, activeFarm } = this.props;
    const { qrCode } = this.state;
    if (action) {
      deleteGrassAnalysis(activeFarm.farm.id, qrCode);
    }
    this.setState({ showDelete: false, grassId: null });
  };

  setGrassAnalyseInitialize = () => {
    const { initialize, grassAnalysis } = this.props;
    grassAnalysis.addHasError = false;
    let fields = [];
    let analysisType = [];
    const formValues = {
      qrCode: '',
      analysisType: analysisType,
      field: fields
    };
    this.setState({
      showHarvest1: false,
      showHarvest2: false,
      showHarvest3: false,
      showHarvest4: false,
      noHarvestError: false,
      noWeightParameter: false,
      qrCodeDisabled: false,
      analysisDate: new Date(),
      showYieldAnalysis: true,
      showFeedMixAnalysis: false,
      showTbdAnalysis: false,
      feedMixArr: [
        {
          fieldId: '',
          fieldName: '',
          harvest: null,
          amount: null,
          unit: '',
          id: ''
        }
      ]
    });
    initialize(formValues);
  };

  openGrassAnalysisModal = (selectedQrCode) => {
    const { grassAnalysis, userDetails } = this.props;
    const selectedGrassAnalysis = _.filter(grassAnalysis.grassAnalysisList, ['qrCode', selectedQrCode]);
    mixpanel.identify(userDetails.id);
    mixpanel.track(ADD_GRASS_ANALYSIS);
    this.setState({
      showGrassAnalysisModal: true,
      selectedQrCode: selectedQrCode,
      selectedGrassAnalysis: selectedGrassAnalysis
    });
    if(selectedGrassAnalysis.length > 0) {
      this.updateInitialize(selectedGrassAnalysis);
    } else {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      this.setState({
        selectedYearDropdown: {value: currentYear, label: currentYear}
      })
    }
  }

  closeGrassAnalysisModal = () => {
    this.setState({
      showGrassAnalysisModal: false
    });
    this.setGrassAnalyseInitialize();
  }

  // To fetch field options
  fieldOptions = (qrCode) => {
    const { fieldDetails } = this.props;
    const { showTbdAnalysis } = this.state;
    if (fieldDetails) {
      let fields = [];
      let keyPair = {};
      if(showTbdAnalysis) {
        keyPair["value"] = "";
        keyPair["label"] = "";
        fields.push(keyPair);
      }
      fieldDetails.map(data => (
        //eslint-disable-next-line
        keyPair = {},
        keyPair["value"] = data.fieldId,
        keyPair["label"] = data.fieldName,
        fields.push(keyPair)
      ));
      return fields;
    }
    if(qrCode !== null) {

    }
    return [];
  };

  // To fetch field options for feed
  fieldOptionsFeed = (qrCode) => {
    const { fieldDetails } = this.props;
    if (fieldDetails) {
      let fieldDetailsArray = [{ value: null, label: "" }];
      let fieldDetail = {};
      fieldDetails.map(data => (
        //eslint-disable-next-line
        fieldDetail = {},
        fieldDetail["value"] = data.fieldId,
        fieldDetail["label"] = data.fieldName,
        fieldDetailsArray.push(fieldDetail)
      ));
      return fieldDetailsArray;
    }
    if(qrCode !== null) {

    }
    return [];
  };

  // To fetch analysis type options
  analysisTypeOptions = (qrCode) => {
    const { intl } = this.props;
    const typeOptions = [];
    typeOptions.push({ value: "YIELD", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_YIELD' }) });
    typeOptions.push({ value: "FEEDMIX", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_FEEDMIX' }) });
    typeOptions.push({ value: "GRASS", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_TBD' }) });
    return typeOptions;
  };

  // To fetch analysis type options
  analysisTypeOptions2 = (qrCode) => {
    const { intl } = this.props;
    const typeOptions = [];
    typeOptions.push({ value: "YIELD", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_YIELD' }) });
    typeOptions.push({ value: "FEEDMIX", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_FEEDMIX' }) });
    typeOptions.push({ value: "GRASS", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_TBD' }) });
    return typeOptions;
  };

  // To fetch harvest options
  harvestOptions = (qrCode) => {
    const harvestOptions = [];
    harvestOptions.push({ value: null, label: ''});
    harvestOptions.push({ value: 1, label: "1"});
    harvestOptions.push({ value: 2, label: "2"});
    harvestOptions.push({ value: 3, label: "3"});
    harvestOptions.push({ value: 4, label: "4"});
    return harvestOptions;
  };

  // To fetch unit options
  unitOptions = (qrCode) => {
    const unitOptions = [];
    unitOptions.push({ value: null, label: ''});
    unitOptions.push({ value: "Kg", label: "Kg"});
    unitOptions.push({ value: "Rundballer", label: "Rundballer"});
    return unitOptions;
  };

  onSelectHarvest = (harvest) => {
    this.setState({
      noHarvestError: false
    });
    this.setState({
      showHarvest1: harvest === 1 ? true : false,
      showHarvest2: harvest === 2 ? true : false,
      showHarvest3: harvest === 3 ? true : false,
      showHarvest4: harvest === 4 ? true : false
    });
  }

  /* To show calendar  */
  showCalendar = () => {
    this.setState({ show: true, updateDuration: false });
  };

  /* To hide calendar  */
  toggleCalendar = () => {
    this.setState({ show: false, updateDuration: true });
  };

  handleSubmit = values => {
    const { activeFarm, registerGrassAnalysis, userDetails } = this.props;
    const { showHarvest1, showHarvest2, showHarvest3, showHarvest4, analysisDate, showFeedMixAnalysis, showTbdAnalysis, feedMixArr } = this.state;
    let analyseType = "YIELD";
    if(showFeedMixAnalysis) {
      analyseType = "FEEDMIX";
    }
    if(showTbdAnalysis){
      analyseType = "GRASS";
    }
    this.setState({
      noHarvestError: false,
      noWeightParameter: false
    });
    let formData = {};
    let fieldId = [];
    if(analyseType === 'YIELD') {
      if(!showHarvest1 && !showHarvest2 && !showHarvest3 && !showHarvest4) {
        this.setState({
          noHarvestError: true
        });
        return;
      }
      if(values.weight !== undefined && values.weight !== null) {
        let valuesWeight = values.weight+'' ? (values.weight+'').replace(',','.') : '';
        if(valuesWeight !== null || valuesWeight !== '') {
          if (isNaN(valuesWeight)) {
            this.setState({
              noWeightParameter: true          
            });        
            return;
          }
        }
      }
      
      if(values.field) {
        values.field.map(fields => (
          fieldId.push(fields.value)
        ));
      }
      let baleHarvest = 1;
      if(showHarvest1) { baleHarvest = 1; }
      if(showHarvest2) { baleHarvest = 2; }
      if(showHarvest3) { baleHarvest = 3; }
      if(showHarvest4) { baleHarvest = 4; }
      formData["analysisType"] = analyseType;
      formData["qrCode"] = values.qrCode;
      formData["farmId"] = activeFarm.farm.id;
      formData["fieldIds"] = fieldId;
      formData["harvest"] = baleHarvest;
      formData["analysisDate"] = formatDateValue(analysisDate);
      formData["weight"] = values.weight;
      formData["year"] = this.state.selectedYearDropdown.value;
      formData["feedMixList"] = [];
      mixpanel.identify(userDetails.id);
      mixpanel.track(GRASS_ANALYSIS_COMPLETE);
      registerGrassAnalysis(activeFarm.farm.id, formData);
    } else if(analyseType === 'FEEDMIX') {
      let feedMixArray = [];
      let formData1 = {};
      feedMixArr.map(feedMix => (
        //eslint-disable-next-line
        feedMix.fieldId = feedMix.fieldId === "" ? null : feedMix.fieldId,
        feedMix.harvest = feedMix.harvest === "" ? null : feedMix.harvest,
        feedMix.unit = feedMix.unit === "" ? null : feedMix.unit,
        feedMix.amount = feedMix.amount+'' ? (feedMix.amount+'').replace(',','.') : null,
        ((feedMix.amount !== null && feedMix.amount !== '0') || feedMix.fieldId !== null || feedMix.harvest !== null || feedMix.unit !== null) && (
          //eslint-disable-next-line
          formData1 = {},
          formData1["fieldId"] = feedMix.fieldId,
          formData1["harvest"] = feedMix.harvest,
          formData1["amount"] = (feedMix.amount && feedMix.amount > 0) ? feedMix.amount : null,
          formData1["unit"] = feedMix.unit,
          feedMixArray.push(formData1)
        )
      ));
      formData["analysisType"] = analyseType;
      formData["qrCode"] = values.qrCode;
      formData["farmId"] = activeFarm.farm.id;
      if(feedMixArray.length > 0) {
        formData["feedMixList"] = feedMixArray;
      } else{
        formData["feedMixList"] = [];
      }
      formData["analysisDate"] = formatDateValue(analysisDate);
      formData["year"] = this.state.selectedYearDropdown.value;
      mixpanel.identify(userDetails.id);
      mixpanel.track(GRASS_ANALYSIS_COMPLETE);
      registerGrassAnalysis(activeFarm.farm.id, formData);
    } else {
        let baleHarvest = null;
        if(showHarvest1) { baleHarvest = 1; }
        if(showHarvest2) { baleHarvest = 2; }
        if(showHarvest3) { baleHarvest = 3; }
        if(showHarvest4) { baleHarvest = 4; }
        formData["analysisType"] = analyseType;
        formData["qrCode"] = values.qrCode;
        formData["farmId"] = activeFarm.farm.id;
        formData["fieldId"] = values.field ? values.field.value !== "" ? values.field.value : null : null;
        formData["harvest"] = baleHarvest;
        formData["analysisDate"] = formatDateValue(analysisDate);
        formData["feedMixList"] = [];
        formData["year"] = this.state.selectedYearDropdown.value;
        mixpanel.identify(userDetails.id);
        mixpanel.track(GRASS_ANALYSIS_COMPLETE);
        registerGrassAnalysis(activeFarm.farm.id, formData);
    }
  }

  onChangeYear = e => {
    let keyPair = {};
    keyPair["value"] = e.value;
    keyPair["label"] = e.value;
    this.setState({
      selectedYearDropdown: keyPair
    });
  }

  handleChange(date) {
    this.setState({  
      analysisDate: date  
    })  
  }

  handleAnalysisTypeChange = (option) => {
    if(option.value === "YIELD") {
      this.setState({
        showYieldAnalysis: true,
        showFeedMixAnalysis: false,
        showTbdAnalysis: false
      });
    } else if(option.value === "FEEDMIX") {
      this.setState({
        showYieldAnalysis: false,
        showFeedMixAnalysis: true,
        showTbdAnalysis: false
      });
    } else {
      this.setState({
        showYieldAnalysis: false,
        showFeedMixAnalysis: false,
        showTbdAnalysis: true
      });
    }
  }

  render() {
    const { intl, activeFarm, handleSubmit, grassAnalysis, deleteGrassAnalysis, hasSupportAdminRole } = this.props;
    const { showGrassAnalysisModal, isCreate, showHarvest1, showHarvest2, showHarvest3, showHarvest4,
      noHarvestError, noWeightParameter, analysisDate, selectedQrCode, showDelete, selectedGrassAnalysis, qrCodeDisabled,
      showYieldAnalysis, showFeedMixAnalysis, showTbdAnalysis } = this.state;
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    const hasPermission = 
    activeFarm && activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data;
    const grassCards =
      grassAnalysis.grassAnalysisList?.length >= 1 &&
      grassAnalysis.grassAnalysisList.map(grass => (
        <GrassAnalysisListCard
          grass={grass}
          farm={activeFarm}
          key={grass.id}
          disable={grassAnalysis.isLoading}
          deleteGrassAnalysis={deleteGrassAnalysis}
          openGrassAnalysisModal={this.openGrassAnalysisModal}
        />
      ));
    let defaultValue = [];
    defaultValue.push({ value: "YIELD", label: intl.formatMessage({ id: 'ANALYSIS_TYPE_YIELD' }) });
    return (
      <div>
        <div>
          {/*<div>
            <div
              className={`link-add-grass grass-edit-screen`}
            >
              <Text>
                <FormattedMessage id="GRASS_ANALYSIS_HEADING" />
              </Text>
            </div>
          </div>*/}
          {hasPermission && grassAnalysis.grassAnalysisList && grassAnalysis.grassAnalysisList.length > 0 &&
          <div style={{ paddingRight: '10px', float: 'right' }}>
            <Stack direction="row" justifyContent="center">
              <Chip
                icon={<PlusIcon />}
                label={intl.formatMessage({ id: 'ADD_GRASS_ANALYSIS' })}
                color="primary"
                variant="outlined"
                clickable
                onClick={() => this.openGrassAnalysisModal(null) }
                style={buttonRoundStyle}
              />
            </Stack>
          </div>}
        <GrassAnalysisRegister
          showGrassAnalysisModal={showGrassAnalysisModal}
          grassAnalysis={grassAnalysis}
          handleSubmit={handleSubmit(this.handleSubmit)}
          intl={intl}
          fieldOptions={this.fieldOptions}
          analysisTypeOptions={this.analysisTypeOptions}
          analysisTypeOptions2={this.analysisTypeOptions2}
          harvestOptions={this.harvestOptions}
          isCreate={isCreate}
          onSelectHarvest={this.onSelectHarvest}
          showHarvest1={showHarvest1}
          showHarvest2={showHarvest2}
          showHarvest3={showHarvest3}
          showHarvest4={showHarvest4}
          analysisDate={analysisDate}
          handleChange={this.handleChange}
          noHarvestError={noHarvestError}
          noWeightParameter={noWeightParameter}
          closeGrassAnalysisModal={this.closeGrassAnalysisModal}
          selectedQrCode={selectedQrCode}
          showDelete={showDelete}
          showDialog={this.showDialog}
          confirmDelete={this.confirmDelete}
          selectedGrassAnalysis={selectedGrassAnalysis}
          qrCodeDisabled={qrCodeDisabled}
          renderFeedmixAmountSection={this.renderFeedmixAmountSection}
          showYieldAnalysis={showYieldAnalysis}
          showFeedMixAnalysis={showFeedMixAnalysis}
          showTbdAnalysis={showTbdAnalysis}
          handleAnalysisTypeChange={this.handleAnalysisTypeChange}
          defaultValue={defaultValue}
          selectedYearDropdown={this.state.selectedYearDropdown}
          onChangeYear={this.onChangeYear}
        />
      </div>
        {grassAnalysis.grassAnalysisList && grassAnalysis.grassAnalysisList.length === 0 &&
          <div className="manageFarmsLayout">
            <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
              <div className="manageFarmsLayoutDiv">
                <div><img src="images/NoGrass.png" alt="No Grass Analysis" width="100" /></div>
                <Box style={inputStyle}>
                  <Stack className="text-with-padding">
                    <div style={titleFont}>
                      {intl.formatMessage({ id: 'GRASS_ANALYSIS' })}
                    </div>
                  </Stack>
                  <Stack className="text-with-padding">
                    <div style={subTitleFont}>
                      {intl.formatMessage({ id: 'NO_GRASS_ANALYSIS_CONTENT' })}
                    </div>
                  </Stack>
                  {hasPermission &&
                  <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                    <Stack direction="row" justifyContent="center">
                      <Chip
                        icon={<PlusIcon />}
                        label={intl.formatMessage({ id: 'ADD_GRASS_ANALYSIS' })}
                        color="primary"
                        variant="outlined"
                        clickable
                        onClick={() => this.openGrassAnalysisModal(null) }
                        style={buttonRoundStyle}
                      />
                    </Stack>
                  </div>}
                </Box>
              </div>
            </div>
          </div>
        }
        {grassAnalysis.grassAnalysisList && grassAnalysis.grassAnalysisList.length >= 1 &&
          <div className="grass-list-container full-width">
            <CssGridLayout>
              <ul className="grass-list-wrapper full-width">{grassCards}</ul>
            </CssGridLayout>
          </div>
        }
        {grassAnalysis.deleteHasSuccess && window.location.reload()}
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { intl } = props;
  if (!values.harvestType || values.harvestType == null) {
    errors.harvestType = intl.formatMessage({ id: 'required' });
  }
  if (!values.qrCode || !values.qrCode.trim() || values.qrCode == null) {
    errors.qrCode = intl.formatMessage({ id: 'required' });
  }
  return errors;
};

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  fieldDetails: state.fieldDetails.data,
  grassAnalysis: state.grassAnalysis,
  hasSupportAdminRole: state.SupportAdminRole
});

const mapDispatchToProps = dispatch => ({
  fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
  getGrassAnalysis: (farmId) => dispatch(grassAnalysisActions.getGrassAnalysis(farmId)),
  registerGrassAnalysis: (farmId, data) => dispatch(grassAnalysisActions.registerGrassAnalysis(farmId, data)),
  deleteGrassAnalysis: (farmId, qrCode) => dispatch(grassAnalysisActions.deleteGrassAnalysis(farmId, qrCode))
});

export default injectIntl(
  reduxForm({
    form: 'GrassAnalysisList',
    validate
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(GrassAnalysisList)))
);
