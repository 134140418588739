import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import * as assetAction from 'actions/Assets';
import { VEHICLE, EQUIPMENT, VEHICLE_CATEGORY, EQUIPMENT_CATEGORY, TYPE4 } from 'constants/index';
import { ADD_VEHICLE_CREATED, ADD_EQUIPMENT_CREATED } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import { Dialog, DialogTitle, Chip, Box, Stack, Grid, TextField } from '@mui/material';
import MuiCreatableSelectBox from 'components/mui-forms/MuiCreatableSelectBox';
import { buttonStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import PesticideAgreement from 'mui-components/PesticideAgreement';
import { PadString } from 'utils/index';
import { EquipmentTranslation, VehicleTranslation } from 'constants/DropdownAndTranslationOptions';

class AddEquipmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equipmentTypeValue: '',
      equipmentType: '',
      equipmentBrand: '',
      equipmentModal: '',
      equipmentYear: '',
      approvedFrom: '',
      approvedTo: '',
      yearHelperText: '',
      pesticideAgreed: false,
      intializeCalled: false,
      selectedDate: '',
      setSelectedDate: '',
      open: false,
      setOpen: false,
    };
    this.handleTypeInputChange = this.handleTypeInputChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleBrandInputChange = this.handleBrandInputChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleModalInputChange = this.handleModalInputChange.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.handleYearInputChange = this.handleYearInputChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  componentDidMount() {
    const { getAssetTypes, assetType, activeFarm } = this.props;
    if (activeFarm) {
      getAssetTypes(assetType, activeFarm.farm.id);
      this.initializeValues();
    }
  }

  componentDidUpdate(prevProps) {
    const { assetsDetails, activeFarm, equipmentProps, getAssetsList } = this.props;
    if (!this.state.intializeCalled && equipmentProps.equipmentSelectedId !== 'new') {
      this.initializeValues();
    }
    if(assetsDetails.isAssetSuccess && !assetsDetails.isAssetListSuccess) {
      equipmentProps.onCloseEquipmentDialog();
      this.setState({
        equipmentTypeValue: '',
        equipmentType: '',
        equipmentBrand: '',
        equipmentModal: '',
        equipmentYear: '',
        yearHelperText: '',
        intializeCalled: false,
        pesticideAgreed: false
      });
      getAssetsList(EQUIPMENT, activeFarm.farm.id);
      // /assetsDetails.isAssetSuccess = false;
    }
  }

  initializeValues = () => {
    const { getAssetEntityDetails, activeFarm, equipmentProps } = this.props;
    let approvedFrom;
    let approvedTo;
    const selectedAsset = this.getSelectedAsset();
    if (equipmentProps.equipmentSelectedId !== 'new' && selectedAsset && selectedAsset !== undefined) {
      if (selectedAsset.asset.type.typeId === TYPE4.id) {
        approvedFrom = selectedAsset.asset.fromDate;
        approvedTo = selectedAsset.asset.toDate;
      }
      this.setState({
        equipmentTypeValue: {value : selectedAsset.asset.type.typeId, label: selectedAsset.asset.type.typeName},
        equipmentType: selectedAsset.asset.type.typeName,
        equipmentBrand: selectedAsset.asset.brand,
        equipmentModal: selectedAsset.asset.model,
        equipmentYear: selectedAsset.asset.year > 0 ? (selectedAsset.asset.year).toString() : '',
        approvedFrom: approvedFrom ? this.changeDateToUsableFormat(approvedFrom) : '',
        approvedTo: approvedTo ? this.changeDateToUsableFormat(approvedTo) : '',
        yearHelperText: '',
        pesticideAgreed: selectedAsset.asset.type.typeId === TYPE4.id ? true : false
      });
      getAssetEntityDetails(selectedAsset.asset.type.typeId, activeFarm.farm.id);
    } else {
      this.setState({
        equipmentTypeValue: '',
        equipmentType: '',
        equipmentBrand: '',
        equipmentModal: '',
        equipmentYear: '',
        approvedFrom: '',
        approvedTo: '',
        yearHelperText: ''
      });
    }
    if (!this.state.intializeCalled && equipmentProps.equipmentSelectedId !== 'new') {
      this.setState({ intializeCalled: true });
    }
  };

  changeDateToUsableFormat = date => {
    const newDate = new Date(date);
    return `${PadString(newDate.getDate())}/${PadString(
      newDate.getMonth() + 1
    )}/${newDate.getFullYear()}`;
  };

  handleTypeInputChange(event, newInputValue) {
    this.setState({
      equipmentType: newInputValue
    });
    this.forceUpdate();
  }

  handleTypeChange(event, newValue) {
    const { getAssetEntityDetails, activeFarm } = this.props;
    if (newValue && !this.getMachineType().map(option => option.label.toLowerCase()).includes(newValue.label.toLowerCase())) {
      this.setState({
        equipmentType: newValue,
        equipmentTypeValue: newValue,
      });
    } else {
      if(newValue) {
        this.setState({
          equipmentType: newValue,
          equipmentTypeValue: newValue
        });
      }
      this.forceUpdate();
      this.setState({ 
        equipmentBrand: '',
        equipmentModal: '',
        equipmentYear: '',
        yearHelperText: '',
        approvedFrom: '',
        approvedTo: '',
        pesticideAgreed: false
      });
    }
    getAssetEntityDetails(newValue ? newValue.value : '', activeFarm.farm.id);
  }

  handleBrandInputChange(event, newInputValue) {
    this.setState({
      equipmentBrand: newInputValue
    });
    this.forceUpdate();
  }

  handleBrandChange(event, newValue) {
    if (newValue && !this.getBrandOptions().map(option => option.label.toLowerCase()).includes(newValue.label.toLowerCase())) {
      this.setState({ equipmentBrand: newValue.value });
    } else {
      if(newValue) {
        this.setState({ equipmentBrand: newValue.value });
      }
      this.forceUpdate();
      this.setState({ 
        equipmentModal: '',
        equipmentYear: '',
        yearHelperText: '',
        approvedFrom: '',
        approvedTo: ''
      });
    }
  }

  handleModalInputChange(event, newInputValue) {
    this.setState({
      equipmentModal: newInputValue
    });
    this.forceUpdate();
  }

  handleModalChange(event, newValue) {
    if (newValue && !this.getModalOptions().map(option => option.label.toLowerCase()).includes(newValue.label.toLowerCase())) {
      this.setState({ equipmentModal: newValue.value });
    } else {
      if(newValue) {
        this.setState({ equipmentModal: newValue.value });
      }
      this.forceUpdate();
      this.setState({
        equipmentYear: '',
        yearHelperText: '',
        approvedFrom: '',
        approvedTo: ''
      });
    }
  }

  handleYearInputChange(event, newInputValue) {
    this.setState({
      equipmentYear: newInputValue
    });
    this.forceUpdate();
  }

  handleYearChange(event, newValue) {
    if (newValue && !this.getYearOptions().map(option => option.label) === (newValue.label)) {
      this.setState({ equipmentYear: (newValue.value).toString() });
    } else {
      if(newValue) {
        this.setState({ equipmentYear: (newValue.value).toString() });
      }
      this.forceUpdate();
    }
    this.setState({ yearHelperText: '' });
  }

  // filtering the active asset
  getSelectedAsset = () => {
    const { assetsDetails, assetType, equipmentProps } = this.props;
    if (assetsDetails.assets[assetType]?.length > 0) {
      return assetsDetails.assets[assetType].filter(item => item.assetAssignmentId === equipmentProps.equipmentSelectedId)[0];
    }
    return null;
  };

  // get type options
  getMachineType = () => {
    const { assetsDetails, assetType, intl } = this.props;
    if (assetsDetails.assetType[assetType]?.length > 0) {
      return _.map(assetsDetails.assetType[assetType], item => ({
        value: item.typeId,
        label: item?.typeName
          ? intl.formatMessage({
              id:
                assetType === VEHICLE
                  ? VehicleTranslation(item.typeName)
                  : EquipmentTranslation(item.typeName)
            })
          : item.typeName
      }));
    }
    return null;
  };

  // To get brand options
  getBrandOptions = () => {
    const { assetsDetails } = this.props;
    if (assetsDetails.typeEntity) {
      const filterUnique = _.orderBy(
        _.uniqBy(assetsDetails.typeEntity, 'brand'),
        [data => data.brand.toLowerCase()],
        ['asc']
      );
      return filterUnique.map(data => ({ value: data.brand, label: data.brand }));
    }
    return [];
  };

  /**
   * get Modal options
   * @returns {Array}
   */
  getModalOptions = () => {
    const { assetsDetails } = this.props;
    if (assetsDetails.typeEntity && this.state.equipmentBrand) {
      const models = assetsDetails.typeEntity.filter(data => data.brand === this.state.equipmentBrand);
      return models && models.length >= 0
        ? _.orderBy(
            _.uniqBy(models, 'model').map(data => ({
              value: data.model,
              label: data.model
            })),
            [data => data.label.toLowerCase()],
            ['asc']
          )
        : [];
    }
    return [];
  };

  /**
   * get year options
   * @returns {Array}
   */
  getYearOptions = () => {
    const { assetsDetails } = this.props;
    const { equipmentBrand, equipmentModal } = this.state;
    if (assetsDetails.typeEntity && equipmentModal && equipmentBrand) {
      const years = assetsDetails.typeEntity.filter(
        data => data.model === equipmentModal && data.brand === equipmentBrand
      );
      return years.length >= 0
        ? _.orderBy(
            _.uniqBy(years, 'year').map(data => ({
              value: data.year,
              label: data.year
            })),
            [data => data.label],
            ['asc']
          )
        : [];
    }
    return [];
  };

  getErrorMessage = () => {
    const {
      assetsDetails: { errorMessage }
    } = this.props;
    if (errorMessage.data?.message) {
      if (errorMessage.data.message.includes('Master Data already exists')) {
        return <ErrorText> Master Data already exists for the brand, model and year</ErrorText>;
      }
      if (errorMessage.data.message.includes('You are trying to edit global')) {
        return <ErrorText>You are trying to edit global data which is not permitted</ErrorText>;
      }
    }
    return null;
  };

  createOrUpdateAsset = () => {
    const { assetType, createAsset, updateAsset, activeFarm, userDetails, equipmentProps, intl, assetsDetails } = this.props;
    let helperText = false;
    let assetObj;
    if (this.state.equipmentYear) {
      const year = this.state.equipmentYear.toString();
      if (year.length !== 4 || !(new Date(year).getFullYear() <= new Date().getFullYear())) {
        this.setState({ yearHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: 'INVALID_YEAR' })}</div> });
        helperText = true;
      }
      if (this.state.equipmentYear < 1950) {
        this.setState({ yearHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: 'ASSET_YEAR_VALID' })}</div> });
        helperText = true;
      }
    }
    if(!helperText){
      if (equipmentProps.equipmentSelectedId !== 'new') {
        this.setState({ yearHelperText: '' });
        const selectedAsset = this.getSelectedAsset();
        assetObj = {};
        if (selectedAsset.asset) {
          assetObj.assetId = selectedAsset.asset.assetId;
          assetObj.year = parseInt(this.state.equipmentYear) || 0;
          assetObj.typeId = selectedAsset.asset.type.typeId;
          assetObj.typeName = selectedAsset.asset.type.typeName;
          assetObj.model = this.state.equipmentModal;
          assetObj.brand = this.state.equipmentBrand;
          assetObj.categoryId = selectedAsset.asset.type.category.categoryId;
          assetObj.fromDate = this.state.approvedFrom ? this.AssetsDateFormate(this.state.approvedFrom) : '';
          assetObj.toDate = this.state.approvedTo ? this.AssetsDateFormate(this.state.approvedTo) : '';
          updateAsset(assetObj, activeFarm.farm.id);
        } else {
          const { onCancle } = this.props;
          onCancle();
        }
      } else {
        const [selectedType] = _.filter(assetsDetails.assetType[assetType], [
          'typeId',
          this.state.equipmentTypeValue.value
        ]);
        assetObj = {
          assetId: '',
          type: {
            typeId: selectedType ? selectedType.typeId : '',
            category: {
              categoryId: assetType === VEHICLE ? VEHICLE_CATEGORY : EQUIPMENT_CATEGORY,
              categoryName: assetType === VEHICLE ? VEHICLE : EQUIPMENT
            },
            typeName: selectedType ? selectedType.typeName : this.state.equipmentType
          },
          brand: this.state.equipmentBrand || '',
          model: this.state.equipmentModal || '',
          year: this.state.equipmentYear || '',
          fromDate: this.state.approvedFrom ? this.AssetsDateFormate(this.state.approvedFrom) : '',
          toDate: this.state.approvedTo ? this.AssetsDateFormate(this.state.approvedTo) : ''
        };
        if(assetType === VEHICLE) {
          mixpanel.identify(userDetails.data.id);
          mixpanel.track(ADD_VEHICLE_CREATED);
        } else {
          mixpanel.identify(userDetails.data.id);
          mixpanel.track(ADD_EQUIPMENT_CREATED);
        }
        createAsset(assetObj, activeFarm.farm.id);
      }
    }
  };

  closePopupWindow = () => {
    const { equipmentProps, assetsDetails, getAssetsList, activeFarm } = this.props;
    this.setState({
      equipmentType: '',
      equipmentTypeValue: '',
      equipmentBrand: '',
      equipmentModal: '',
      equipmentYear: '',
      yearHelperText: '',
      approvedFrom: '',
      approvedTo: '',
      intializeCalled: false
    });
    equipmentProps.onCloseEquipmentDialog();
    if(assetsDetails.hasAssetError) {
      getAssetsList(EQUIPMENT, activeFarm.farm.id);  
    }
  }

  formatDate = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  };
  
  formatToInputValue = (date) => {
    if (!date) return '';
    const [day, month, year] = date.split('.');
    if(year === undefined) {
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    } else {
      return `${year}-${month}-${day}`;
    }
  };

  AssetsDateFormate = (date) => {
    if (!date) return '';
    const [dd, mm, yyyy] = date.split('.');
    if(yyyy === undefined) {
      const [dd, mm, yyyy] = date.split('/');
      return `${yyyy}-${mm}-${dd}`;
    } else {
      return `${yyyy}-${mm}-${dd}`;
    }
  }

  handleFromDateChange = (e) => {
    const { approvedTo } = this.state;
    const formattedDate = this.formatDate(e.target.value);
    this.setState({ approvedFrom: formattedDate });

    // Reset 'To' date if it is before the selected 'From' date
    if (formattedDate && approvedTo && this.formatToInputValue(formattedDate) > this.formatToInputValue(approvedTo)) {
      this.setState({ approvedTo: '' });
    }
  };

  handleToDateChange = (e) => {
    this.setState({ approvedTo: this.formatDate(e.target.value) });
  };

  render() {
    const {
      intl,
      assetsDetails,
      equipmentProps
    } = this.props;
    const dialogTitleStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "24px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
    const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", paddingTop: "0px"  }
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px", width: '100%' };
    const scrollStyle = {
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      scrollbarWidth: 'thin',
      scrollbarColor: '#6F7979 #E6E9E8'
    }
    const agreementText = {
      color: "#191C1C",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.5px"
    }
    const { equipmentType, equipmentTypeValue, equipmentBrand, equipmentModal, equipmentYear, pesticideAgreed } = this.state;
    return (
      <div>    
        <Dialog onClose={() => {}} open={equipmentProps.showAddEquipmentDialog} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "397px",
            minWidth: "280px",
            maxWidth: "560px",
          }}}>
          <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'EQUIPMENT' })}</DialogTitle>
          <Box component="div" style={scrollStyle}>
            <Box component="div" style={dialogSubTitleStyle}>
              {intl.formatMessage({ id: 'ADD_EQUIPMENT_DIALOG' })}
            </Box>
            <div className="manageFarmsLayoutDialogDiv">
              <div><img src="images/CreateEquipment.png" alt="Equipment" /></div>
              <Box style={inputStyle}>
                <Stack className='text-with-padding'>
                  <MuiCreatableSelectBox
                    name="type"
                    id="type"
                    label={intl.formatMessage({ id: 'MACHINE_TYPE' })}
                    variant="outlined"
                    options={this.getMachineType()}
                    inputValue={equipmentType} 
                    handleInputChange={this.handleTypeInputChange} 
                    handleChange={this.handleTypeChange}
                    disabled={equipmentProps.equipmentSelectedId !== 'new'}
                    value={equipmentType}
                    labelName={intl.formatMessage({ id: 'create' })}
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiCreatableSelectBox 
                    name="brand"
                    id="brand"
                    label={intl.formatMessage({ id: 'MACHINE_BRAND' })}
                    variant="outlined"
                    options={this.getBrandOptions()}
                    inputValue={equipmentBrand} 
                    handleInputChange={this.handleBrandInputChange} 
                    handleChange={this.handleBrandChange} 
                    value={equipmentBrand}
                    labelName={intl.formatMessage({ id: 'create' })}
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiCreatableSelectBox 
                    name="model"
                    id="model"
                    label={intl.formatMessage({ id: 'MACHINE_MODEL' })}
                    variant="outlined"
                    options={this.getModalOptions()}
                    inputValue={equipmentModal} 
                    handleInputChange={this.handleModalInputChange} 
                    handleChange={this.handleModalChange} 
                    value={equipmentModal}
                    labelName={intl.formatMessage({ id: 'create' })}
                  />
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Stack>
                      <MuiCreatableSelectBox 
                        name="year"
                        id="year"
                        label={intl.formatMessage({ id: 'MACHINE_YEAR' })}
                        variant="outlined"
                        options={this.getYearOptions()}
                        inputValue={equipmentYear} 
                        handleInputChange={this.handleYearInputChange} 
                        handleChange={this.handleYearChange} 
                        value={equipmentYear}
                        labelName={intl.formatMessage({ id: 'create' })}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>&nbsp;</Grid>
                </Grid>
                <Stack>
                  {equipmentType !== '' && equipmentType !== null && equipmentTypeValue.value === TYPE4.id && (
                    <Box style={{ paddingTop: '50px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Stack>
                            <TextField
                              label={intl.formatMessage({ id: 'SPRAYER_FROM_DATE' })}
                              type="date"
                              value={this.formatToInputValue(this.state.approvedFrom)}
                              onChange={this.handleFromDateChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          < Stack>
                            <TextField
                              label={intl.formatMessage({ id: 'SPRAYER_TO_DATE' })}
                              type="date"
                              value={this.formatToInputValue(this.state.approvedTo)}
                              onChange={this.handleToDateChange}
                              InputProps={{
                                inputProps: {
                                  min: this.formatToInputValue(this.state.approvedFrom), // Set the minimum selectable date
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={!this.formatToInputValue(this.state.approvedFrom)} // Disable until a 'From' date is selected
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                      <Stack style={agreementText}>
                        <PesticideAgreement
                          onChange={e => {
                            this.setState({ pesticideAgreed: e });
                          }}
                          agreed={pesticideAgreed}
                        />
                      </Stack>
                    </Box>
                  )}
                </Stack>
              </Box>
            </div>
            {assetsDetails.hasAssetError ? <div style={{padding: '20px 30px 0px 30px', color: '#cc0000'}}>{this.getErrorMessage()}</div> : ''}
            {this.state.yearHelperText && <div style={{padding: '20px 30px 0px 30px', color: '#cc0000'}}>{this.state.yearHelperText}</div>}
            <div style={{padding: "10px 24px 30px 30px", textAlign: "right"}}>
              <span>
                <Chip
                  label={intl.formatMessage({ id: 'cancel' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  onClick={this.closePopupWindow}
                  disableRipple
                />
                <Chip
                  label={intl.formatMessage({ id: 'SAVE' })}
                  clickable
                  className={`${(equipmentType === '' || equipmentType === null || equipmentBrand === '' || equipmentBrand === null || 
                    equipmentModal === null || equipmentModal === '' || (this.state.equipmentTypeValue.value === TYPE4.id ? !pesticideAgreed : pesticideAgreed)) ? 'disabled' : ''}`}
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  onClick={() => this.createOrUpdateAsset()}
                  disableRipple
                />
              </span>
            </div>
          </Box>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAssetTypes: (category, FarmId) => dispatch(assetAction.getAssetTypes(category, FarmId)),
  getAssetEntityDetails: (typeId, farmId) => dispatch(assetAction.getAssetEntityDetails(typeId, farmId)),
  createAsset: (data, farmId) => dispatch(assetAction.createAsset(data, farmId)),
  updateAsset: (data, farmId) => dispatch(assetAction.updateAsset(data, farmId)),
  getAssetsList: (category, farmId) => dispatch(assetAction.getFarmAssetList(category, farmId))
});

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  assetsDetails: state.assetsDetails,
  formValues: getFormValues('AddEquipmentModal')(state)
});

export default injectIntl(
  reduxForm({
    form: 'AddEquipmentModal'
  })(connect(mapStateToProps, mapDispatchToProps)(AddEquipmentModal))
);
