/**
 * Fertilization index component has tab module to switch between the fertilizer planner componenets
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import TabBar from '@tine/lib-frontend-components/components/navigation/tab-bar';
import { injectIntl } from 'react-intl';

import Header from 'containers/header';
import FertilizerPlanner from 'containers/fertilization/planner/FertilizerPlanner';
import SoilSamples from 'containers/fertilization/soil';
import { FERTILIZATION_PLAN, SOIL_SAMPLE } from 'constants/index';
import { pageview } from 'utils/GoogleAnalytics';

class Settings extends React.Component {
  state = {
    selected: null,
    tabIndex: 0,
  };

  componentDidMount = () => {
    const { location, activeFarm, history } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }

    if (location.pathname.includes(SOIL_SAMPLE)) {
      this.setState({
        selected: SOIL_SAMPLE,
        tabIndex: 1
      });
    } else {
      this.setState({
        selected: FERTILIZATION_PLAN,
        tabIndex: 0
      });
    }
  };

  // on tab change passing page title to the header
  onTabChange = (index, key) => {
    const { history, location } = this.props;
    this.setState({ selected: key, tabIndex: index });
    // Capture GA pageview events for tag change
    const pagePaths = ['/fertilization', '/manure-storage', '/soil-samples'];
    pageview(pagePaths[index]);
    if (location.pathname !== '/fertilization') {
      history.push('/fertilization');
    }
  };

  // Render tab content
  renderContent = index => [
    <FertilizerPlanner />, 
    <SoilSamples />
  ][index];

  getTitle = title => {
    const {
      pageContent: { labels }
    } = this.props;
    let pageTitle = '';
    if (labels) {
      switch (title) {
        case SOIL_SAMPLE:
          pageTitle = labels.soilSamples;
          break;
        default:
          pageTitle = labels.fertilizationPlan;
          break;
      }
    }
    return pageTitle;
  };

  render() {
    const { selected, tabIndex } = this.state;
    const {
      pageContent: { labels },
      intl
    } = this.props;
    if (!selected) {
      return null;
    }
    return (
      <div className="main-content">
        <Header bcTitle={labels.fertilizationPlan} />
        <div className="settings-tabs">
          <TabBar
            initialTab={selected}
            secondary
            onTabChange={(index, key) => this.onTabChange(index, key)}
            tabBarIdentifier="setting_tab"
            tabs={[
              { 
                title: intl.formatMessage({ id: 'FERTILIZATION_PLAN' }), 
                key: FERTILIZATION_PLAN, 
                disabled: false },
              {
                title: intl.formatMessage({ id: 'SOIL_SAMPLES' }),
                key: SOIL_SAMPLE,
                disabled: false
              }
            ]}
          />
          {this.renderContent(tabIndex)}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  pageContent: _.filter(state.masterData.data[0]?.pages, ['pageId', 'manureStoragePage'])[0]
});
export default injectIntl(withRouter(connect(mapStateToProps)(Settings)));
