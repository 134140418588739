import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import mixpanel from 'mixpanel-browser';
import { IMPORT_FIELDS, EXPORT_FIELDS } from 'constants/MixPanel';

import CommonErrorMessage from 'components/CommonErrorMessage';
import ConfirmDialogBox from 'components/ConfirmDialogBox';
import Loader from 'components/Loader';
import FieldCard from 'mui-components/fields/fieldtab/FieldCard';
import FieldUpdateForm from 'mui-components/fields/fieldtab/FieldsUpdate';
import * as fieldActions from 'actions/Fields';
import * as exportActions from 'actions/ExportShape';
import * as locationActions from 'actions/Geolocation';
import { checkPermissions } from 'utils/index';
import { CREATE_FIELD, ADMIN, OWNER, ADVISOR } from 'constants/index';
import * as Seasons from 'actions/Seasons';
import * as FieldMethod from 'actions/FieldMethod';
import * as FieldMasterData from 'actions/FieldMasterData';
import * as fieldChartActions from 'actions/FieldChart';
import shp from "shpjs";
import shpwrite from "shp-write-mm";
import Fertilization from 'mui-components/fields/fertilization/index';
import { FIELD_FERTILIZATION, MANAGEFIELDS } from 'constants/index';
import CreateFertilizationPlan from 'mui-components/fields/fertilization/CreateFertilizationPlan';
import { buttonRoundStyle, buttonFilledStyle, buttonGreenStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';

import { Stack, Box, Tabs, Tab, Typography, Chip, Grid, Menu, MenuItem, Dialog, DialogTitle } from '@mui/material';
import * as FertilizerAction from 'actions/FertilizationPlan';
import { PlusIcon } from 'components/mui-forms/MuiIcons';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderRadius: '15px',
    padding: "15px"
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index === 0 ? 'customFieldTabDiv' : 'customFertilizerTabDiv'}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class Fields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: new Map(),
      deleteConfirmShow: false,
      clearSelection: false,
      showUpdateForm: false,
      imported: false,
      errorZipMessage: false,
      errorMessage: false,
      exportData: false,
      tabIndex: 0,
      weatherData: false,
      totalFieldArea: 0,
      selectedTotalFieldArea: 0,
      totalFieldLength: 0,
      selectedTotalFieldLength: 0,
      setTotalFieldArea: false,
      deleteFieldList: false,
      selectAllFields: false,
      value: 0,
      selectedFieldId: null,
      anchorEl: false,
      selected: null,
      showDeletePromptError: false
    };
  }

  componentDidMount() {
    const { fieldDetails, fetchFieldList, fetchFieldList1, activeFarm, getGeoLocation, history, location, seasonsList, fieldMethodList, 
      getFieldCrops, getPreviousFieldCrops, fetchWeatherData, fertilizationPlanList } = this.props;
    if (!activeFarm) {
      history.push('/settings');
      
    }
    seasonsList();
    fieldMethodList();
    // To get current location
    getGeoLocation();
    getFieldCrops();
    getPreviousFieldCrops();
    fieldDetails.data = [];
    fieldDetails.data1 = [];
    if (activeFarm) {
      // To get field list
      fetchFieldList(activeFarm.farm.id);
      fetchFieldList1(activeFarm.farm.id);
      fetchWeatherData(activeFarm.farm.id);
      fertilizationPlanList(activeFarm.farm.id);
    }
    if (location.pathname.includes(FIELD_FERTILIZATION)) {
      this.setState({
        selected: FIELD_FERTILIZATION,
        value: 1
      });
    } else {
      this.setState({
        selected: MANAGEFIELDS,
        value: 0
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { history, activeFarm, fetchFieldList, fetchFieldList1, fieldDetails, exportShape, fieldDetailsState } = this.props;
    const { isFieldDeleteSuccess } = fieldDetails;
    const { setTotalFieldArea, exportData, weatherData, deleteFieldList, showDeletePromptError } = this.state;
    if(!weatherData) {
      this.setState({
        weatherData: true
      })
    }
    if (!activeFarm) {
      history.push('/settings');
    }
    if (activeFarm !== prevProps.activeFarm) {
      this.setState({
        showUpdateForm: false,
        checkedItems: new Map(),
        setTotalFieldArea: false
      });
      fetchFieldList(activeFarm.farm.id);
      fetchFieldList1(activeFarm.farm.id);
    }
    if (fieldDetails !== prevProps.fieldDetails) {
      this.setState({
        showUpdateForm: false,
        checkedItems: new Map(),
        setTotalFieldArea: false
      });
    }
    if(fieldDetails && fieldDetails.data1 && fieldDetails.data1.fields && fieldDetails.data1.fields.length > 0 && !setTotalFieldArea) {
      let fieldArea = 0;
      if(fieldDetails.data1.fields && fieldDetails.data1.fields.length > 0) {
        // eslint-disable-next-line
        fieldDetails.data1.fields.map(fieldData => {
          fieldArea = fieldArea + parseFloat((fieldData.fieldArea).toFixed(2));
        });
      }
      this.setState({
        totalFieldArea: Math.round(fieldArea),
        totalFieldLength: fieldDetails.data1.fields.length,
        setTotalFieldArea: true
      });
    }
    if(exportData && (exportShape && exportShape.exportSuccess !== null)) {
      this.downloadShapeFile(exportShape.exportSuccess);
      this.setState({
        exportData: false
      });
    }
    if(deleteFieldList && isFieldDeleteSuccess) {
      fieldDetails.data = "";
      fieldDetails.data1 = "";
      fieldDetails.isFieldDeleteSuccess = false;
      fetchFieldList(activeFarm.farm.id);
      fetchFieldList1(activeFarm.farm.id);
      this.clearSelection(true);
      this.setState({
        setTotalFieldArea: false,
        deleteFieldList: false,
        selectedTotalFieldLength: 0,
        selectedTotalFieldArea: 0,
        showDeletePromptError: false
      })
    }
    if(!showDeletePromptError && fieldDetailsState.isFieldDeleteFailure) {
      this.setState({
        showDeletePromptError: true
      });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history } = this.props;    
    if(newValue === 1) {
      this.setState({ selected: FIELD_FERTILIZATION, tabIndex: newValue, value: newValue });
      history.push("/managefields/fertilization");
    } else {
      this.setState({ selected: MANAGEFIELDS, tabIndex: newValue, value: newValue });
      history.push("/managefields");
    }
  };

  downloadShapeFile = (shapeObject) => {
    var options = {
      folder: shapeObject.fileName,
      file: shapeObject.fileName,
      types: {
          polygon: shapeObject.fileName
      }
    }
    shpwrite.download(shapeObject, options);
  }

  clearSelection = clear => {
    const { clearSelection } = this.state;
    const { fieldDetails } = this.props;
    const { isBatchError, isBatchSuccess } = fieldDetails;
    if (clearSelection || clear) {
      if (isBatchError || isBatchSuccess) {
      } else {
        this.setState({
          clearSelection: true,
          selectedTotalFieldLength: 0,
          selectedTotalFieldArea: 0
        });
      }      
    }
    if (isBatchError || isBatchSuccess) {
      if (!isBatchError && isBatchSuccess) {
        this.setState({
          showUpdateForm: false,
          checkedItems: new Map()
        });
      }
    }
  };

  clearSelectionMap = clear => {
    const { clearSelection } = this.state;
    const { fieldDetails } = this.props;
    const { isBatchError, isBatchSuccess } = fieldDetails;
    if (clearSelection || clear) {
      if (isBatchError || isBatchSuccess) {
      } else {
        this.setState({
          clearSelection: true,
          selectedTotalFieldLength: 0,
          selectedTotalFieldArea: 0,
          checkedItems: new Map(),
          selectAllFields: false
        });
      }      
    }
    if (isBatchError || isBatchSuccess) {
      if (!isBatchError && isBatchSuccess) {
        this.setState({
          showUpdateForm: false,
          checkedItems: new Map(),
          selectAllFields: false
        });
      }
    }
  };

  // Check or un-check the field list item
  handleCheckbox = e => {
    const { fieldDetails } = this.props;
    const { selectedTotalFieldArea, selectedTotalFieldLength } = this.state;
    const item = e.target.id;
    const seletedField = _.filter(fieldDetails.data1.fields, ['fieldId', item]);
    const isChecked = e.target.checked;
    let totalFieldArea = selectedTotalFieldArea;
    let totalFieldLength = selectedTotalFieldLength;
    if(e.target.checked) {
      totalFieldArea = parseFloat(totalFieldArea) + parseFloat(seletedField[0].fieldArea.toFixed(2));
      totalFieldLength = parseInt(totalFieldLength) + 1;
    } else {
      totalFieldArea = parseFloat(totalFieldArea) - parseFloat(seletedField[0].fieldArea.toFixed(2));
      totalFieldLength = parseInt(totalFieldLength) - 1;
    }
    if(totalFieldLength === fieldDetails.data1.fields && fieldDetails.data1.fields.length) {
      this.setState({
        selectAllFields: true
      });
    } else {
      this.setState({
        selectAllFields: false
      });
    }
    this.setState({
      selectedTotalFieldArea: Math.round((parseFloat(totalFieldArea))),
      selectedTotalFieldLength: parseInt(totalFieldLength)
    })
    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  };

  // Check or un-check the field list item
  handleCheckboxAll = () => {
    const { fieldDetails } = this.props;
    const { selectAllFields } = this.state;
    let totalFieldArea = 0;
    if(!selectAllFields) {
      // eslint-disable-next-line
      fieldDetails.data1.fields.map(data => {
        totalFieldArea = parseFloat(totalFieldArea) + parseFloat(data.fieldArea.toFixed(2));
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(data.fieldId, true) }));
      });
      this.setState({
        selectAllFields: true,
        selectedTotalFieldArea: Math.round((parseFloat(totalFieldArea))),
        selectedTotalFieldLength: parseInt(fieldDetails.data1.fields.length)
      });
    } else {
      this.clearSelectionMap(true);
    }
  };

  //to export the shape file
  exportShapeFile = () => {
    const { activeFarm, exportShapeFile, userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(EXPORT_FIELDS);
    this.setState({ anchorEl: false });
    exportShapeFile(activeFarm.farm.id, activeFarm.farm.name);
    this.setState ({
      exportData: true
    });
  }

  getDeleteConfirmMsg = selectedCount => {
    const { intl } = this.props;
    return `<div class="confirm-msg-wrapper"> 
        <p>${intl.formatMessage({ id: 'DIALOG_BODY_FIELD' })} </p>
      </div>`;
  };

  // To get the selected field items from the list
  getSelectedFields = values => new Map([...values].filter(([k, v]) => v === true));

  /**
   * to delete fields
   * @param {boolean} action
   */
   deleteFieldItems = action => {
    const { deleteListFields, activeFarm } = this.props;
    const { checkedItems, selectedFieldId } = this.state;
    if (action) {
      if(selectedFieldId !== null) {
        const fields = [];
        fields.push(selectedFieldId);
        this.setState({ deleteFieldList: true, selectedFieldId: null });
        deleteListFields(activeFarm.farm.id, fields);
      } else {
        const selectedItem = this.getSelectedFields(checkedItems);
        const fields = [];
        selectedItem.forEach((v, k) => fields.push(k));
        this.setState({ deleteFieldList: true });
        deleteListFields(activeFarm.farm.id, fields);
      }
    }
    this.clearSelection(true);
    this.setState(prevState => ({
      deleteConfirmShow: !prevState.deleteConfirmShow,
      clearSelection: true,
      setTotalFieldArea: false,
      selectedTotalFieldLength: 0,
      selectedTotalFieldArea: 0,
      checkedItems: new Map()
    }));
  };

  deleteFieldErrorMessage() {
    const { fieldDetailsState, fieldDetails, fetchFieldList, fetchFieldList1, activeFarm } = this.props;
    fieldDetailsState.isFieldDeleteFailure = false;
    fieldDetails.data = "";
    fieldDetails.data1 = "";
    fieldDetails.isFieldDeleteSuccess = false;
    this.setState(prevState => ({ showDeletePromptError: !prevState.showDeletePromptError }));
    fetchFieldList(activeFarm.farm.id);
    fetchFieldList1(activeFarm.farm.id);
    this.clearSelection(true);
    this.setState({
      setTotalFieldArea: false,
      deleteFieldList: false,
      selectedTotalFieldLength: 0,
      selectedTotalFieldArea: 0,
      showDeletePromptError: false
    });
  }

  // Handle file change input file
  handleFileChange = event => {
    const { userDetails } = this.props;
    mixpanel.identify(userDetails.id);
    mixpanel.track(IMPORT_FIELDS);
    //this.setState({ anchorEl: false });
    let shapeFile = false;
    if(event.target.files[0]) {
      if(event.target.files[0].type && event.target.files[0].type.includes('zip')) {
        this.setState({ errorMessage: false, errorZipMessage: false });
        if (event.target.files.length > 0) {
          const { activeFarm, importShape } = this.props;
          let fileReader = new FileReader();
          let dataArray = [];
          fileReader.onload= () => {
            const geojson = shp(fileReader.result);
            geojson.then(response => {
            }).catch(e => {
              this.setState({ errorMessage: true, errorZipMessage: false, anchorEl: false });
            });            
            geojson.then((data) => {         
              if(data.length === undefined) {
                dataArray.push(data);
              } else {
                dataArray = data;
              }
              //eslint-disable-next-line
              dataArray.map((jsonData) => {
                //console.log("jsonData: ",jsonData);
                let shapeDataObject = {
                  "type": jsonData.type,
                  "features": []
                };
                if(jsonData.features) {
                  //eslint-disable-next-line
                  jsonData.features.map((featureData) => {
                    let propertyValue = {};
                    if((featureData.properties.name && featureData.properties.name !== null) || (featureData.properties.Field && featureData.properties.Field !== null)) {
                      propertyValue["name"] = featureData.properties.name ? featureData.properties.name: featureData.properties.Field ? featureData.properties.Field : null;
                    }
                    if((featureData.properties.area && featureData.properties.area !== null) || (featureData.properties.AREA && featureData.properties.AREA !== null)) {
                      propertyValue["area"] = featureData.properties.area ? featureData.properties.area: featureData.properties.AREA ? featureData.properties.AREA: null;
                    }
                    if(featureData.properties.nid && featureData.properties.nid !== null) {
                      propertyValue["nid"] = featureData.properties.nid ? featureData.properties.nid: null;
                    }
                    if(featureData.properties.uid && featureData.properties.uid !== null) {
                      propertyValue["uid"] = featureData.properties.uid ? featureData.properties.uid: null;
                    }
                    if(featureData.properties.municipali && featureData.properties.municipali !== null) {
                      propertyValue["municipalityNumber"] = featureData.properties.municipali ? featureData.properties.municipali: null;
                    }
                    let featureDataObject = {
                      "geometry": {
                        "type": featureData.geometry.type,
                        "coordinates": featureData.geometry.coordinates
                      },
                      "properties": propertyValue
                    };
                    if((featureDataObject.geometry.type).toLowerCase() === "polygon") {
                      shapeDataObject.features.push(featureDataObject);
                    }
                  })
                }
                //return;
                if(shapeDataObject.features && shapeDataObject.features.length > 0) {
                  shapeFile = true;
                  importShape(shapeDataObject, activeFarm.farm.id);
                  this.setState({ anchorEl: false });
                }
              });
              if(!shapeFile) {
                this.setState({ errorMessage: true, errorZipMessage: false, anchorEl: false });
              }
            });
          };
          fileReader.readAsArrayBuffer(event.target.files[0]);
        } else {
          this.setState({ errorMessage: true, errorZipMessage: false });
        }
      } else {
        this.setState({ errorZipMessage: true, errorMessage: false });
      }
    }
  };

  /**
   * To render field list by condtions
   */
  renderFarmFields = () => {
    const { checkedItems, deleteConfirmShow } = this.state;
    const { activeFarm, fieldDetails, fieldMasterData, rolePermissions, hasSupportAdminRole, intl } = this.props;
    const { data, data1, isLoading } = fieldDetails;
    const disableIcon = checkPermissions(CREATE_FIELD, activeFarm, rolePermissions, hasSupportAdminRole.data);
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: '14px', width: '340px' };
    const titleFont = {
      color: '#191C1C',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '32px'
    }
    const subTitleFont = {
      color: '#3F4949',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    }
    const farmCards =
      data1 && data1.fields?.length >= 1 &&
      _.orderBy(data, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']).map(field => (
        <FieldCard
          field={field}
          farm={activeFarm}
          key={field.fieldId}
          disable={isLoading}
          handleCheckbox={this.handleCheckbox}
          checked={!!checkedItems.get(field.fieldId)}
          fieldMasterData={fieldMasterData}
          deleteConfirmShow={deleteConfirmShow}
          updateState={this.updateState}
          disableIcon={disableIcon}
        />
      ));
    if (data1 && data1.fields && data1.fields.length === 0) {
      return (
      <div className="manageFarmsLayout">
        <div className="manageFarmsLayoutDivLayout" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
          <div className="manageFarmsLayoutDiv">
          <div><img src="images/NoFields.png" alt="No Fields" width="120" /></div>
            <Box style={inputStyle}>
              <Stack className="text-with-padding">
                <div style={titleFont}>
                  {intl.formatMessage({ id: 'FIELDS' })}
                </div>
              </Stack>
              <Stack className="text-with-padding">
                <div style={subTitleFont}>
                  {intl.formatMessage({ id: 'NO_FIELDS_CONTENT' })}
                </div>
              </Stack>
              <div style={{ padding: '20px 0px', paddingRight: '0px !important' }}>
                <Stack direction="row" justifyContent="center">
                  <Chip
                    icon={<PlusIcon />}
                    label={intl.formatMessage({ id: 'FV_AF' })}
                    color="primary"
                    variant="outlined"
                    clickable
                    onClick={() => this.addNewField()}
                    style={buttonRoundStyle}
                  />
                </Stack>
              </div>
              <div style={{ paddingRight: '0px !important' }}>
                <Stack direction="row" justifyContent="center">
                  <input
                    type="file"
                    id="file-input"
                    accept="application/zip"
                    style={{ display: 'none' }}
                    onChange={event => this.handleFileChange(event)}
                  />
                  <span variant="outlined" onClick={(event)=>this.handleButtonClick(event)} style={buttonGreenStyle}>
                    {intl.formatMessage({ id: 'IMPORT_SHAPE' })}
                  </span>
                </Stack>
              </div>
            </Box>
          </div>
        </div>
      </div>
      );
    }
    return (
      <ul className="field-list-wrapper full-width">
        {farmCards}
      </ul>
    );
  };

  toggleUpdateForm = () => {
    this.setState({
      showUpdateForm: true
    });
  };

  toggleUpdateCloseForm = () => {
    this.setState({
      showUpdateForm: false,
      checkedItems: new Map()
    });
  };

  // To get the selected field items from the list
  getSelectedFields = values => new Map([...values].filter(([k, v]) => v === true));

  addNewField = () => {
    const { history } = this.props;
    history.push("/managefields/new");
  };

  updateState = (newState, fieldId) => {
    this.setState({
      deleteConfirmShow: newState,
      selectedFieldId: fieldId
    });
  };

  handleClick = (event) => {
    event.preventDefault();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (event) => {
    event.preventDefault();
    //event.stopPropagation();
    this.setState({ anchorEl: false });
    //document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    if (this.state.anchorEl && !this.state.anchorEl.contains(event.target)) {
      event.preventDefault();
      this.handleClose(event);
    }
  };

  handleButtonClick = (event) => {
    document.removeEventListener('click', this.handleOutsideClick);
    document.getElementById('file-input').click();
    //document.addEventListener('click', this.handleOutsideClick);
  };

  getToolTipValue(cropAreaMap) {
    const subHeadingSmallTextLeft = { fontFamily: "Inter", fontSize: "14px", fontWeight: "400", textAlign: "left" };
    const subHeadingSmallCapitalizeTextLeft = { ...subHeadingSmallTextLeft, textTransform: "Capitalize", textAlign: "left", fontWeight: "400" }
    let data = '';
    if (cropAreaMap !== null) {
      return Object.entries(cropAreaMap).map(([key, value]) => {
        data = (<Grid container justifyContent="flex-end" sx={{ paddingTop: '10px' }}>
          <Grid item lg={8} sm={8} md={8} xs={12}>
            <Typography style={subHeadingSmallCapitalizeTextLeft}>
              {key}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={4} md={4} xs={12}>
            <Typography style={subHeadingSmallTextLeft}>
              {value} daa
            </Typography>
          </Grid>
        </Grid>);
        return data;
      });
    }
  }

  render() {
    const { activeFarm, intl, fieldDetails, seasons, fieldMethod, fieldMasterData, exportShape, history, hasSupportAdminRole, fieldDetailsState, 
      fertilizationPlan} = this.props;
    const { data1, isLoading, hasError, isImportSuccess } = fieldDetails;
    const { isExportLoading } = exportShape;
    const { checkedItems, showUpdateForm, deleteConfirmShow, value, errorMessage, errorZipMessage } = this.state;
    const selectedCount = this.getSelectedFields(checkedItems).size;
    const disableCreateFieldEditButton = (activeFarm && activeFarm.role ? activeFarm.role.id === OWNER || activeFarm.role.id === ADMIN || activeFarm.role.id === ADVISOR : hasSupportAdminRole.data);
    const buttonStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: 14, color: '#00696D', padding: '10px' };
    const dialogTitleDialogStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "16px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "center", color: "#191C1C" };
    const tabStyle = { 
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      color: '#00696D',
      textTransform: 'none',
      padding: '0px 30px'
    };
    const menuItemStyle = {
      backgroundColor: 'transparent',
      padding: '8px 16px',
      fontFamily: 'Inter',
      fontSize: '14px'
    };
    const titleText = { fontFamily: "Inter", fontSize: "16px", fontWeight: "500" };
    const headingTextLeftAlign = { ...titleText, fontSize: "12px", fontWeight: "600", textAlign: "left" };
    return (
      <div className="main-route-area">
        <div className="fields-container">
          <div className="manage-field-content full-width">
              <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1 }}>
                <Grid container justifyContent="flex-end">
                  <Grid item md={value === 0 ? 8 : 10}>
                    <Tabs variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      value={value} onChange={this.handleChange} className='mui-tabs'>
                      <Tab style={tabStyle} label={intl.formatMessage({ id: 'HEADER_FDS' })} {...a11yProps(0)} />
                      <Tab style={tabStyle} label={intl.formatMessage({ id: 'FIELD_FERTILIZATION' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                      {/*<Tab style={tabStyle} label="Varmesum" {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                      <Tab style={tabStyle} label="Skiftetabell" {...a11yProps(2)} sx={{wordSpacing:"15%"}} />
                      <Tab style={tabStyle} label="Gjødselplan" {...a11yProps(3)} sx={{wordSpacing:"15%"}} />
                      <Tab style={tabStyle} label="Jordprøver" {...a11yProps(4)} sx={{wordSpacing:"15%"}} />*/}
                    </Tabs>
                  </Grid>
                  {value === 0 &&
                  (<Grid item md={2}>
                    {errorZipMessage && <span className="error-message">
                      {intl.formatMessage({ id: 'INVALID_ZIP_FILE_FORMAT' })}
                    </span>}
                    {errorMessage && <span className="error-message">
                      {intl.formatMessage({ id: 'INVALID_FILE_FORMAT' })}
                    </span>}
                  </Grid>)}
                  {value === 0 &&
                  (<Grid item md={2} className='grid-padding-top'>                    
                    <Stack direction="row" justifyContent="end">
                      {disableCreateFieldEditButton && 
                      (<Chip
                        icon={<PlusIcon />}
                        label={intl.formatMessage({ id: 'FV_AF' })}
                        color="primary"
                        variant="outlined"
                        clickable
                        style={buttonStyle}
                        onClick={() => this.addNewField()}
                      />)}
                      <Box component='span' className='grid-padding-dot'>
                        <MoreVertIcon onClick={(e)=>this.handleClick(e)} sx={{ cursor: 'pointer' }} />
                        <Menu
                          id="basic-menu"
                          anchorEl={this.state.anchorEl}
                          open={Boolean(this.state.anchorEl)}
                          onClose={(e)=>this.handleClose(e)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem
                            style={menuItemStyle}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
                            }}
                          >
                            <input
                              type="file"
                              id="file-input"
                              accept="application/zip"
                              style={{ display: 'none' }}
                              onChange={event => this.handleFileChange(event)}
                            />
                            <span variant="outlined" onClick={(event)=>this.handleButtonClick(event)}>
                              {intl.formatMessage({ id: 'IMPORT_SHAPE' })}
                            </span>
                          </MenuItem>
                          <MenuItem
                            onClick={this.exportShapeFile}
                            style={menuItemStyle}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
                            }}
                          >
                            {intl.formatMessage({ id: 'EXPORT_SHAPE' })}
                          </MenuItem>
                          {selectedCount > 0 &&
                          <MenuItem
                            onClick={() => {
                              this.setState({
                                showUpdateForm: true,
                                anchorEl: false
                              });                              
                            }}
                            style={menuItemStyle}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
                            }}
                          >
                            {intl.formatMessage({ id: 'EDIT_SELECTION' })}
                          </MenuItem>}
                          {selectedCount > 0 &&
                          <MenuItem
                            onClick={() => {
                              this.setState(prevState => ({
                                anchorEl: false,
                                deleteConfirmShow: !prevState.deleteConfirmShow
                              }));
                            }}
                            style={menuItemStyle}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = '#f1f1f1'; // Change color on hover
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = 'transparent'; // Reset color on leave
                            }}
                          >
                            {intl.formatMessage({ id: 'DELETE_SELECTED' })}
                          </MenuItem>}
                        </Menu>
                      </Box>
                    </Stack>
                  </Grid>)}
                  {value === 1 && ((fertilizationPlan.planList && fertilizationPlan.planList.fertilizerPlanMap !== null) || 
                    (fertilizationPlan.data && fertilizationPlan.data !== null)) &&
                  (<CreateFertilizationPlan
                    createNew={1}
                  />)}
                </Grid>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {data1 && data1.fields && data1.fields.length > 0 &&
                <div style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '0.1px',
                  paddingLeft: '28px',
                  paddingBottom: '20px'
                }}>
                  <span style={{verticalAlign: 'text-bottom'}}>{intl.formatMessage({ id: 'TOTAL' })}: {data1.totalArea} daa</span>
                  <span style={{paddingLeft: '10px', verticalAlign: 'sub'}}>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Grid container justifyContent="flex-end">
                              <Grid item lg={8} sm={8} md={8} xs={12}>
                                <Typography style={headingTextLeftAlign}>{intl.formatMessage({ id: 'TOOLTIP_FIELD_HEADER1' })}</Typography>
                              </Grid>
                              <Grid item lg={4} sm={4} md={4} xs={12}>
                                <Typography style={headingTextLeftAlign}>{intl.formatMessage({ id: 'TOOLTIP_FIELD_HEADER2' })}</Typography>
                              </Grid>
                            </Grid>
                            {this.getToolTipValue(data1.cropAreaMap)}
                          </React.Fragment>
                        }
                      >
                        <InfoIcon />
                      </HtmlTooltip>
                  </span>
                </div>}
                <div>{data1 && this.renderFarmFields()}</div>
              </CustomTabPanel>
              {/*<CustomTabPanel value={value} index={1}>
                Item 2
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                Item 3
              </CustomTabPanel>*/}
              <CustomTabPanel value={value} index={1}>
                <Fertilization />
              </CustomTabPanel>
              {/*<CustomTabPanel value={value} index={4}>
                Item 5
              </CustomTabPanel>*/}
            {isLoading && <Loader styleName="field-loader" />}
            {isExportLoading && <Loader styleName="field-loader" />}
            {isImportSuccess && (
              history.go(0)
            )}
            {hasError && !isLoading && <CommonErrorMessage />}            
          </div>
          {showUpdateForm && seasons.seasonsList !== null && fieldMethod.fieldMethodList && fieldMasterData && fieldMasterData.cropList !== null && (
            <FieldUpdateForm
              showUpdateField={showUpdateForm}
              selectedField={checkedItems}
              toggleUpdateForm={this.toggleUpdateForm}
              toggleUpdateCloseForm={this.toggleUpdateCloseForm}
              seasons={seasons}
              fieldMethod={fieldMethod}
              fieldMasterData={fieldMasterData}
            />
          )}
          {/*selectedCount > 0 && disableIcon ? (
            <div className="task-list-update">
              <span className="selected-task">
                {intl.formatMessage({ id: 'YOU_SELECTED' })} <span>{selectedCount}</span>{' '}
                {intl.formatMessage({ id: 'FIELDS' })}
              </span>
              <ActionButton tertiary onClick={() => this.clearSelectionMap(true)}>
                {intl.formatMessage({ id: 'REMOVE_SELECTION' })}
              </ActionButton>
              <span className="update-field-btn harvestTextHigh">
                <ActionButton secondary onClick={() => this.toggleUpdateForm()}>
                  {intl.formatMessage({ id: 'EDIT_SELECTION' })}
                </ActionButton>
              </span>
              <div className="delete-tasks padding-top-low">
                <span>{intl.formatMessage({ id: 'DELETE_SELECTED' })}</span>
                <IconButton
                  onClick={() => {
                    this.setState(prevState => ({
                      deleteConfirmShow: !prevState.deleteConfirmShow
                    }));
                  }}
                >
                  <TrashcanIcon scale={0.85} />
                </IconButton>
              </div>
            </div>
                ) : null*/}
        </div>
        {deleteConfirmShow ? (
          <ConfirmDialogBox
            heading={`${intl.formatMessage({ id: 'AEF_DF' })}?`}
            body={this.getDeleteConfirmMsg(selectedCount)}
            confirmText="DELETE"
            cancelText="CANCEL"
            callback={e => this.deleteFieldItems(e)}
            warning
          />
        ) : null}
        <Dialog onClose={() => {}} open={this.state.showDeletePromptError} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "397px",
            minWidth: "280px",
            maxWidth: "560px",
          }}}>
          <DialogTitle style={dialogTitleDialogStyle}>
            <img src="./images/alertIcon.svg" className="alert-icon-size" alt="draw" /><br/>
            {fieldDetailsState.errorMessage?.data}
          </DialogTitle>
          <div style={{padding: "15px 15px 25px 15px", textAlign: "center"}}>
            <span>
              <Chip
                label={intl.formatMessage({ id: 'OK' })}
                clickable
                style={buttonFilledStyle}
                sx={{ backgroundColor: 'transparent',
                  '&:hover, &:focus': {
                    backgroundColor: 'transparent',
                  }
                }}
                disableRipple
                onClick={() => this.deleteFieldErrorMessage()}
              />
            </span>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  farmList: state.farmList,
  rolePermissions: state.RolesDetails,
  fieldDetails: state.fieldDetails,
  seasons: state.seasons,
  fieldMethod: state.fieldMethod,
  fieldMasterData: state.fieldMasterData,
  exportShape: state.exportShape,
  userDetails: state.userDetails,
  hasSupportAdminRole: state.SupportAdminRole,
  fieldDetailsState: state.fieldDetails,
  fertilizationPlan: state.fertilizationPlan
});

function mapDispatchToProps(dispatch) {
  return {
    fetchFieldList: farmId => dispatch(fieldActions.fetchFieldList(farmId)),
    fetchFieldList1: farmId => dispatch(fieldActions.fetchFieldList1(farmId)),
    getGeoLocation: () => dispatch(locationActions.getGeoLocation()),
    seasonsList: () => dispatch(Seasons.seasonsList()),
    fieldMethodList: () => dispatch(FieldMethod.fieldMethodList()),
    getFieldCrops: () => dispatch(FieldMasterData.getFieldCrops()),
    getPreviousFieldCrops: () => dispatch(FieldMasterData.getPreviousFieldCrops()),
    importShape: (data, farmId) => dispatch(fieldActions.importShape(data, farmId)),
    exportShapeFile: (farmId, farmName) => dispatch(exportActions.exportShape(farmId, farmName)),
    fetchWeatherData: (farmId) => dispatch(fieldChartActions.fieldTemperatureSum(farmId, null)),
    deleteListFields: (farmId, fieldIds) => dispatch(fieldActions.deleteListFields(farmId, fieldIds)),
    fertilizationPlanList: farmId => dispatch(FertilizerAction.fertilizationPlanList(farmId))
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Fields)));
