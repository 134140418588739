import React from 'react';
import { FormControl, InputLabel, Select, OutlinedInput, Radio, Box } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';

const MuiRadioButton = ({
  label,
  options,
  onChange,
  value,
  InputLabelProps
}) => {
  return (
    <FormControl sx={{ m: 1 }}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="dropdown-radio-label"
        input={<OutlinedInput label={label} />}
        value={value !== null ? value : ''}
        onChange={onChange}
        InputLabelProps={InputLabelProps}
        renderValue={(value) => {
          const selectedItem = options.find((option) => option.value === value);
          return selectedItem ? selectedItem.label : '';
        }}
        sx={{
          width: '170px',
          float: 'right',
          color: '#6F7979',
          '.MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#6F7979'
            }
          },
          '.MuiSelect-select': {
            '&:focus': {
              borderColor: '#6F7979'
            }
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }, }}>
            <Box component="span" sx={{ width: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {option.label}
            </Box>
            <Radio
              checked={value === option.value}
              value={undefined || option.value}
              sx={{
                color: '#6F7979',
                '&.Mui-checked': {
                  color: '#00696D',
                },
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MuiRadioButton;
