import React from 'react';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import Fields from 'mui-components/fields/fieldtab/Fields';

const ManageFields = ({ intl }) => (
  <div className="main-content">
    <Header bcTitle={intl.formatMessage({ id: 'HEADER_FDS' })} />
    <Fields />
  </div>
);

export default injectIntl(ManageFields);
