import * as React from 'react';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import {
  getHarvest
} from 'utils/index';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { Stack, Grid, List, ListItemButton, Collapse } from '@mui/material'

const getYieldResponseData = (yieldPlan, yieldSumValue, yieldPercentValue, intl, changeYieldValueByCalculation, selectedField) => {
  const smallTextBox = {
    textAlign: 'center',
    paddingRight: 20
  };
  return (
    yieldPlan && yieldPlan.totalYield > 0 ?
    <List component="div" className='list-grid-box'>
      <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
        <Grid container justifyContent="flex-end">
          {getYieldResponseDataHarvestTitle(yieldPlan, intl)}
          <Grid item md={1.5} style={smallTextBox}>
            Kontrollsum
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
      </ListItemButton>
      <ListItemButton disableRipple sx={{ paddingTop: 2, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
        <Grid container justifyContent="flex-end">
          {getYieldResponseDataHarvest(yieldPlan, changeYieldValueByCalculation, selectedField)}
          <Grid item md={1.5} style={smallTextBox}>
            <Stack>
              <MuiTextBox 
                name="kontrollsum"
                id="kontrollsum"
                label=""
                variant="outlined"
                value={yieldSumValue}
                helperText={selectedField.actualYields.controlSum}
                disabled={true}
                showFullWidth="true"
              />
            </Stack>
          </Grid>
          <Grid item md={6} sx={{ paddingTop: 2}}>Avling i kg ts/daa</Grid>
        </Grid>
      </ListItemButton>
      <ListItemButton disableRipple sx={{ paddingTop: -5, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>
        <Grid container justifyContent="flex-end">
          {getYieldResponseDataPercentage(yieldPlan, changeYieldValueByCalculation, selectedField)}
          <Grid item md={1.5} style={smallTextBox}>
            <Stack>
              <MuiTextBox 
                name="percentage"
                id="percentage"
                label=""
                variant="outlined"
                value={yieldPercentValue}
                helperText={selectedField.actualYields.controlSum}
                disabled={true}
                showFullWidth="true"
              />
            </Stack>
          </Grid>
          <Grid item md={6} sx={{ paddingTop: 2}}>Fordeling i %</Grid>
        </Grid>
      </ListItemButton>
    </List>
    : ''
  )
}

const getYieldResponseDataHarvest = (yieldPlan, changeYieldValueByCalculation, selectedField) => {
  const smallTextBox = {
    textAlign: 'center',
    paddingRight: 20
  };
  if (yieldPlan.plannedYields?.length > 0) {
    return yieldPlan.plannedYields.map((plannedYield,index) => (
      (index < yieldPlan.harvests) ?
      <Grid item md={1.5} style={smallTextBox}>
        <Stack>
          <MuiTextBox 
            name={`yiedHarvest_${index}`}
            id={`yiedHarvest_${index}`}
            label=""
            variant="outlined"
            helperText={selectedField.actualYields.harvestYields[index].harvestYield}
            value={plannedYield.yield}
            onChange={changeYieldValueByCalculation}
            showFullWidth="true"
          />
        </Stack>
      </Grid>
      : ''
    ))
  }
}

const getYieldResponseDataHarvestTitle = (yieldPlan, intl) => {
  const smallTextBox = {
    textAlign: 'center',
    paddingRight: 20
  };
  if (yieldPlan.plannedYields?.length > 0) {
    let labelName1 = intl.formatMessage({ id: 'FIRST_HARVEST_YIELD' });
    let labelName2 = intl.formatMessage({ id: 'SECOND_HARVEST_YIELD' });
    let labelName3 = intl.formatMessage({ id: 'THIRD_HARVEST_YIELD' });
    let labelName4 = intl.formatMessage({ id: 'FOURTH_HARVEST_YIELD' });
    return yieldPlan.plannedYields.map((plannedYield,index) => (
      (index < yieldPlan.harvests) ?
      <Grid key={index} item md={1.5} style={smallTextBox}>
        {index === 0 ? labelName1 : ''}
        {index === 1 ? labelName2 : ''}
        {index === 2 ? labelName3 : ''}
        {index === 3 ? labelName4 : ''}
      </Grid>
      : ''
    ))
  }
}

const getYieldResponseDataPercentage = (yieldPlan, changeYieldValueByCalculation, selectedField) => {
  const smallTextBox = {
    textAlign: 'center',
    paddingRight: 20
  };
  if (yieldPlan.plannedYields?.length > 0) {
    return yieldPlan.plannedYields.map((plannedYield,index) => (
      (index < yieldPlan.harvests) ?
      <Grid item md={1.5} style={smallTextBox}>
        <Stack>
          <MuiTextBox 
            name={`yiedHarvestPercent_${index}`}
            id={`yiedHarvestPercent_${index}`}
            label=""
            variant="outlined"
            helperText={selectedField.actualYields.harvestYields[index].harvestYield}
            value={plannedYield.yieldPercent}
            onChange={changeYieldValueByCalculation}
            showFullWidth="true"
          />
        </Stack>
      </Grid>
      : ''
    ))
  }
}

const YieldGoalComponent = ({ handleClickYieldGoal, lblPage, intl, disableGrain, open2, yieldCalculation, yieldGoalValue,
  setYieldGoal, selectedField, harvestValue, setHarvest, showHarvest, changedYieldValue, yieldSumValue, yieldPercentValue,
  changeYieldValueByCalculation}) => (
  <List
    sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0' }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    <ListItemButton disableRipple sx={{ '&:hover': {backgroundColor: 'transparent'} }} onClick={handleClickYieldGoal}>
      <span><ListItemText primary={intl.formatMessage({ id: 'AEF_CN' })} /></span>
      <span>{open2 ? <ExpandLess /> : <ExpandMore />}</span>
    </ListItemButton>
    {yieldCalculation &&
    <Collapse in={open2} timeout="auto" unmountOnExit>             
      <List component="div" className='list-grid-box'>
        <ListItemButton disableRipple sx={{ width: '40%',  paddingTop: 4, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'} }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={12}>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="yieldGoal"
                  id="yieldGoal"
                  label={lblPage.labels.yieldGoal}
                  variant="outlined"
                  value={yieldGoalValue}
                  onChange={setYieldGoal}
                  helperText={selectedField && selectedField.actualYields && selectedField.actualYields.totalYield}
                  showFullWidth="true"
                />
              </Stack>
              {disableGrain === false &&
              <Stack className='text-with-padding'>
                <MuiSelectBox 
                  name="harvests"
                  id="harvests"
                  label={lblPage.labels.harvests}
                  variant="outlined"
                  value={harvestValue}
                  options={getHarvest(4)}
                  onChange={setHarvest}
                />
              </Stack>}
            </Grid>
          </Grid>
        </ListItemButton>
      </List>
      {showHarvest && disableGrain === false &&
        getYieldResponseData(changedYieldValue, yieldSumValue, yieldPercentValue, intl, changeYieldValueByCalculation, selectedField)
      }
    </Collapse>}
  </List>
);

export default YieldGoalComponent;